import React from 'react';
import { Link } from 'react-router-dom';
import { pageTitle } from '../PageTitle';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import img1 from "../../assets/images/bg/page-title-1.jpg";
import img2 from "../../assets/images/shapes/top-bar.png";
import img3 from "../../assets/images/shapes/bottom-bar.png";
import img4 from "../../assets/images/shapes/shape-12.png";

const Error = () => {
  pageTitle('Error');

  return (
    <>
      <HeaderTwo />

      <section className="error-section p-relative" style={{ backgroundImage: `url(${img1})` }}>
        <div className="bar-top" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="bar-bottom" style={{ backgroundImage: `url(${img3})` }}></div>
        <div className="yellow-shape" style={{ backgroundImage: `url(${img4})` }}></div>
        <div className="custom-container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="inner p-relative text-center">
                    <h1>404</h1>
                    <h2 className="mb-70 mt-25">page is not found. the page is doesn’t <br/>exist or deleted</h2>
                    <div className="header-link">
                        <Link className="theme__button " to="/">
                            Go To Home <i className="icon-arrow-double-right"></i>
                            
                        </Link>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      <FooterTwo />
    </>
  );
};

export default Error;
