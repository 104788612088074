import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";

import blog1 from "../../assets/images/blogs/blog-4.jpg";
import blog2 from "../../assets/images/blogs/blog-5.jpg";
import blog3 from "../../assets/images/blogs/blog-6.jpg";

import img1 from "../../assets/images/services/service-sidebar.jpg";
import img2 from "../../assets/images/shapes/shape-27.png";
import img3 from "../../assets/images/shapes/shape-28.png";
import img4 from "../../assets/images/services/service-details.jpg";
import img5 from "../../assets/images/services/service-details-2.jpg";

import 'bootstrap/dist/css/bootstrap.min.css';

const ServiceDetails = () => {
  pageTitle('Service Details');

  // State to manage which accordion is open
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    if (activeAccordion === index) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  return (
    <>
      <HeaderTwo></HeaderTwo>
      <BreadCrumb></BreadCrumb>

      <section className="section-space">
        <div className="custom-container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
                <aside>
                    <div className="service-details-widgets">
                        <div className="sd-widgets bg-color-3 mb-50">
                            <h5 className="mb-20">Our Services</h5>
                            <hr />
                            <ul className="sd-list-items">
                                <li>
                                    <Link to="#">
                                        <span>House Renovation</span>
                                        <i className="fal fa-arrow-up-right"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <span>Material Supply</span>
                                        <i className="fal fa-arrow-up-right"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <span>Welding & Laser</span>
                                        <i className="fal fa-arrow-up-right"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="active" to="#">
                                        <span>Structural Engineering</span>
                                        <i className="fal fa-arrow-up-right"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <span>House Renovation</span>
                                        <i className="fal fa-arrow-up-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="sd-widgets-2 bg-color-3 mb-50">
                            <h5 className="mb-20">Latest Posts</h5>
                            <hr />
                            <div className="sd-blog-post-sidebar-area mt-30">
                                <div className="blog-post-sidebar">
                                    <Link className="w-img blog-sidebar-thumb" to="blog-details">
                                        <img src={blog1} alt="" />
                                    </Link>
                                    <div className="content">
                                        <span><i className="fal fa-calendar-alt"></i> 05 Dec, 2023</span>
                                        <h6 className="blog-sidebar-post-title mt-10"><Link to="blog-details">How to Boost Productivity in the Factory Process</Link></h6>
                                    </div>
                                </div>
                                <div className="blog-post-sidebar">
                                    <Link className="w-img blog-sidebar-thumb" to="blog-details">
                                        <img src={blog2} alt="" />
                                    </Link>
                                    <div className="content">
                                        <span><i className="fal fa-calendar-alt"></i> 05 Dec, 2023</span>
                                        <h6 className="blog-sidebar-post-title mt-10"><Link to="blog-details">How to Boost Productivity in the Factory Process</Link></h6>
                                    </div>
                                </div>
                                <div className="blog-post-sidebar">
                                    <Link className="w-img blog-sidebar-thumb" to="blog-details">
                                        <img src={blog3} alt="" />
                                    </Link>
                                    <div className="content">
                                        <span><i className="fal fa-calendar-alt"></i> 05 Dec, 2023</span>
                                        <h6 className="blog-sidebar-post-title mt-10"><Link to="blog-details">How to Boost Productivity in the Factory Process</Link></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sd-widgets-3">
                            <div className="cta-area p-relative">
                                <figure className="w-img">
                                    <img src={img1} alt="" />
                                </figure>
                                <div className="shape-1" style={{ backgroundImage: `url(${img2})` }}></div>
                                <div className="shape-2" style={{ backgroundImage: `url(${img3})` }}></div>
                                <div className="content-area">
                                    <h5 className="sd-title mb-30">Where should I incorporate my business?</h5>
                                    <div className="chat-us">
                                        <div className="icon-2">
                                            <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M31.4058 6.35513C28.1145 4.32519 24.2144 3.20898 20.1855 3.20898C9.21848 3.20898 0 11.3798 0 21.8418C0 25.5099 1.1395 29.0262 3.30401 32.063L0.28032 41.556C-0.0391289 42.5588 0.711981 43.5801 1.76008 43.5801C1.99971 43.5801 2.24091 43.5247 2.46346 43.4115L11.659 38.736C12.0313 38.8963 12.4083 39.0455 12.7891 39.184C10.6601 35.8609 9.52344 32.0464 9.52344 28.0527C9.52344 16.1591 19.5311 7.01546 31.4058 6.35513Z" fill="#FF8F27"/>
                                                <path d="M49.696 38.274C51.8605 35.2371 53 31.7208 53 28.0527C53 17.5869 43.7776 9.41992 32.8145 9.41992C21.8474 9.41992 12.6289 17.5907 12.6289 28.0527C12.6289 38.5186 21.8513 46.6855 32.8145 46.6855C35.7561 46.6855 38.6871 46.0861 41.3405 44.9467L50.5365 49.6224C51.0946 49.9061 51.7687 49.8269 52.2458 49.4215C52.7229 49.016 52.9098 48.3636 52.7198 47.7671L49.696 38.274ZM26.5 29.6055C25.6425 29.6055 24.9473 28.9103 24.9473 28.0527C24.9473 27.1952 25.6425 26.5 26.5 26.5C27.3575 26.5 28.0527 27.1952 28.0527 28.0527C28.0527 28.9103 27.3575 29.6055 26.5 29.6055ZM32.7109 29.6055C31.8534 29.6055 31.1582 28.9103 31.1582 28.0527C31.1582 27.1952 31.8534 26.5 32.7109 26.5C33.5685 26.5 34.2637 27.1952 34.2637 28.0527C34.2637 28.9103 33.5685 29.6055 32.7109 29.6055ZM38.9219 29.6055C38.0644 29.6055 37.3691 28.9103 37.3691 28.0527C37.3691 27.1952 38.0644 26.5 38.9219 26.5C39.7794 26.5 40.4746 27.1952 40.4746 28.0527C40.4746 28.9103 39.7794 29.6055 38.9219 29.6055Z" fill="#707070"/>
                                            </svg>
                                        </div>
                                        <div className="content p-relative z-1">
                                            <span>Chat Us Anytime</span>
                                            <h5><Link to="tel:4065550120">(406) 555-0120</Link></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="service-details-content-area">
                <figure className="image w-img">
                        <img src={img4} alt="" />
                    </figure>
                    <h3 className="mt-40 mb-25">Structural Engineering</h3>
                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.</p>
                    <div className="row">
                        <div className="col-lg-6">
                            <figure className="image w-img">
                                <img src={img5} alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <div className="icon-content-area">
                                <div className="inner">
                                    <div className="icon-box-1">
                                        <i className="icon-crain"></i>
                                    </div>
                                    <div className="content">
                                        <h5>The Art and Science of Building</h5>
                                        <p>We leverage cutting-edge technologies to create compelling content.</p>
                                    </div>
                                </div>
                                <div className="inner">
                                    <div className="icon-box-1">
                                        <i className="icon-mixer-truck"></i>
                                    </div>
                                    <div className="content">
                                        <h5>The Art and Science of Building</h5>
                                        <p>We leverage cutting-edge technologies to create compelling content.</p>
                                    </div>
                                </div>
                                <div className="inner">
                                    <div className="icon-box-1">
                                        <i className="icon-concrete-mixer-1"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Engineering Marvels in Construction</h5>
                                        <p>We leverage cutting-edge technologies to create compelling content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="mb-40">Frequently Ask Questions?</h3>
                <div className="vw-faq">
                  <div className="accordion" id="accordionExample-st-2">
                    
                    {faqData.map((faq, index) => (
                      <div className="vw-faq-group" key={index}>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`heading-${index}`}
                          >
                            <button
                              className={`accordion-button ${
                                activeAccordion === index ? '' : 'collapsed'
                              }`}
                              type="button"
                              onClick={() => toggleAccordion(index)}
                              aria-expanded={activeAccordion === index}
                              aria-controls={`collapse-${index}`}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse-${index}`}
                            className={`accordion-collapse collapse ${
                              activeAccordion === index ? 'show' : ''
                            }`}
                            aria-labelledby={`heading-${index}`}
                            data-bs-parent="#accordionExample-st-2"
                          >
                            <div className="accordion-body">
                              {faq.answer}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterTwo></FooterTwo>
    </>
  );
};

const faqData = [
  {
    question: "Where should I incorporate my business?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  },
  {
    question: "Can I offer my items for free on a promotional basis?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  },
  {
    question: "How can I safely use My Business?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  },
  {
    question: "Can I offer my items for free on a promotional basis?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  }
];

export default ServiceDetails;
