import React from 'react';
import FooterOne from '../../Footer/FooterOne';
import HeaderOne from '../../Header/HeaderOne';
import BannerOne from '../../HomeOne/BannerOne/BannerOne';
import AboutOne from '../../HomeOne/AboutOne/AboutOne';
import Service from '../../HomeOne/Services/Services';
import ServiceTwo from '../../HomeOne/ServiceTwo/ServiceTwo';
import ProjectsOne from '../../HomeOne/ProjectsOne/ProjectsOne';
import FeaturesOne from '../../HomeOne/FeaturesOne/FeaturesOne';
import TeamOne from '../../HomeOne/TeamOne/TeamOne';
import Testimonial from '../../HomeOne/Testimonial/Testimonial';
import FormOne from '../../HomeOne/FormOne/FormOne';
import Blog from '../../HomeOne/Blog/Blog';
import Brand from '../../HomeOne/Brand/Brand';

import { pageTitle } from '../../PageTitle';


const HomeOne = () => {

    pageTitle('Buildsphere');
    return (
        <>
            <div className="bar-1"></div>
            <div className="bar-2"></div>
            <div className="bar-3"></div>
            <div className="bar-4"></div>
            
            <HeaderOne></HeaderOne>
            <BannerOne></BannerOne>
            <AboutOne></AboutOne>
            <Service></Service>
            <ServiceTwo></ServiceTwo>
            <ProjectsOne></ProjectsOne>
            <FeaturesOne></FeaturesOne>
            <TeamOne></TeamOne>
            <Testimonial></Testimonial>
            <FormOne></FormOne>
            <Blog></Blog>
            <Brand></Brand>
  
            <FooterOne></FooterOne>
        </>
    );
};

export default HomeOne;