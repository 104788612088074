import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import img1 from "../../assets/images/resources/about-page-1.jpg";
import img2 from "../../assets/images/shapes/shape-26.png";
import img3 from "../../assets/images/resources/about-page-2.png";
import ServiceTwo from '../HomeOne/ServiceTwo/ServiceTwo';
import TextAnimationThree from '../HomeTwo/TextAnimationThree/TextAnimationThree';
import TeamThree from '../HomeThree/TeamThree/TeamThree';
import Brand from '../HomeOne/Brand/Brand';



const About = () => {
      pageTitle('About Us');
      
  return (
    
    <>

        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>

        <section className="about-us-page section-space">
          <div className="custom-container">
              <div className="image-box p-relative">
                <figure className="image w-img">
                    <img src={img1} alt="" />
                </figure>
                <figure className="image-2 text-end">
                    <img src={img2} alt="" />
                </figure>
              </div>
              <div className="row section-space-top">
                <div className="col-lg-5 col-md-12">
                    <div className="about-us-content-area two">
                      <div className="title-box mb-15 wow fadeInRight" data-wow-delay=".5s">
                          <span className="section-sub-title">About Us</span>
                          <h3 className="section-title mt-10">Engineering Marvels in Construction</h3>
                      </div>
                      <p className="about-us-2-text">We leverage cutting-edge technologies to create compelling content across various digital platforms.</p>
                      <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer nec quam ut tortor efficitur consectetur sed vitae nisi. Phasellus convallis vulputate euismod. Pellentesque lacinia rutrum libero, sit amet aliquam ante viverra a. Ut sem ipsum, tempor nec rutrum in, gravida eu ipsum.</p>
                      <div className="icon-content-area pt-20 pb-20 border-0">
                          <div className="inner">
                            <div className="icon-box-1">
                                <i className="icon-crain"></i>
                            </div>
                            <div className="content">
                                <h5>Science of Building</h5>
                                <p>We leverage cutting-edge technologies.</p>
                            </div>
                          </div>
                          <div className="inner">
                            <div className="icon-box-1">
                                <i className="icon-mixer-truck"></i>
                            </div>
                            <div className="content">
                                <h5>Engineering Marvels</h5>
                                <p>We leverage cutting-edge technologies.</p>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12">
                    <figure className="w-img text-end">
                      <img className="image-1" src={img3} alt="" />
                    </figure>
                </div>
              </div>
          </div>
        </section>

        <ServiceTwo></ServiceTwo>
        
        <section className="text-slider-section fix section-space-top">
            <div className="container-fluid">
              <div className="text-slider-box-1">
                <div className="slide-box one">
                    <h1>*Hand wood</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Out door</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Hand wood</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Out door</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Hand wood</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Out door</h1>
                </div>
              </div>
            </div>
          </section>

        <TextAnimationThree></TextAnimationThree>
        <TeamThree></TeamThree>
        <Brand></Brand>

        <FooterTwo></FooterTwo>
    </>
  );
};

export default About;