import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'swiper/css';
import "swiper/css/pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import './assets/sass/Style.scss';
import ScrollTop from './Pages/ScrollTop/ScrollTop';




const RootComponent = () => {


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      // Simulating the delay and fade out
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };

    window.addEventListener('load', handleLoad);

    // Cleanup function
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);


  return (
    <>  
      {isLoading && (
       <div id="preloader">
          <div className="vw-loader-inner">
            <div className="vw-loader">
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
                <span className="vw-loader-item"></span>
            </div>
          </div>
      </div>
      )}
      <div className="bar-wrapper-container p-relative">
          

          <React.StrictMode>
              <App />
          </React.StrictMode>
          <ScrollTop />
      </div>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
reportWebVitals();
