import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/banner/banner-2.jpg";
import img2 from "../../../assets/images/shapes/shape-12.png";
import img3 from "../../../assets/images/shapes/top-bar.png";
import img4 from "../../../assets/images/shapes/bottom-bar.png";
import img5 from "../../../assets/images/shapes/about-bg-shape.png";
import img6 from "../../../assets/images/banner/banner-2.png";

import WOW from 'wowjs';

const BannerTwo = () => {

      useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (

    <>
      <section className="banner-2-section p-relative fix" style={{ backgroundImage: `url(${img1})` }}>
        <div className="yellow-shape" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="bar-shape-top" style={{ backgroundImage: `url(${img3})` }}></div>
        <div className="bar-shape-bottom" style={{ backgroundImage: `url(${img4})` }}></div>
        <div className="custom-container">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="banner-2-content p-relative img-custom-anim-left">
                    <h1 className="banner-title">Construction & Building Agency</h1>
                    <p className="banner-text">Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                    <div className="banner-link">
                        <Link className="theme__button " to="/services">
                          Explore Services <i className="icon-arrow-double-right"></i>
                          
                        </Link>
                    </div>
                  </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="shape-bg-1 pt-80 pb-130 wow fadeInLeft" data-wow-delay="700ms" style={{ maskImage: `url(${img5})`, WebkitMaskImage: `url(${img5})` }}>
                    <img className="image-1" src={img6} alt="" />
                  </div>
              </div>
            </div>
        </div>
      </section>
    </>

  );
};

export default BannerTwo;
