import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import img1 from "../assets/images/bg/page-title-1.jpg";
import img2 from "../assets/images/shapes/top-bar.png";
import img3 from "../assets/images/shapes/bottom-bar.png";
import img4 from "../assets/images/shapes/shape-12.png";

const BreadCrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (

    <div className="breadcrumb__area theme-bg-1 p-relative" style={{ backgroundImage: `url(${img1})` }}>
        <div className="bar-top" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="bar-bottom" style={{ backgroundImage: `url(${img3})` }}></div>
        <div className="yellow-shape" style={{ backgroundImage: `url(${img4})` }}></div>
        <div className="custom-container">
            <div className="row justify-content-center">
                <div className="col-xxl-12">
                    <div className="breadcrumb__wrapper p-relative">
                        <div className="breadcrumb__menu">
                        <nav>
                            <ul>
                                <li><Link to="/"><i className="icon-36"></i> Home</Link></li>
                                {pathnames.map((name, index) => (
                                    <li key={index}>
                                        <span>
                                            <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                                        </span>
                                        
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        </div>
                        <h2 className="breadcrumb__title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

   

  );
};

export default BreadCrumb;
