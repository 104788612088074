import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/resources/about-4.png";
import WOW from 'wowjs';

const AboutThree = () => {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <>
         <section className="about-us-section two three section-space p-relative fix">
            <div className="custom-container">
                <div className="row">
                    <div className="col-xxl-7 col-xl-7 col-lg-7">
                        <div className="about-us-image-area two p-relative">
                        <div className="image-box-area">
                            <figure className="image_1">
                                <img src={img1} alt="" />
                            </figure>
                        </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5">
                        <div className="about-us-content-area two">
                        <div className="title-box mb-15 wow fadeInRight" data-wow-delay=".5s">
                            <span className="section-sub-title">About Us</span>
                            <h3 className="section-title mt-10">Engineering Marvels in Construction</h3>
                        </div>
                        <p className="about-us-2-text">We leverage cutting-edge technologies to create compelling content across various digital platforms.</p>
                        <p>For residential, automotive and commercial locksmith services choose Locksmith West Los Angeles, we are knowledgeable and leading the industry in the local area for years. We have wide experience on any job that may involve locks, keys and security.</p>
                        <div className="icon-content-area pt-20 pb-20">
                            <div className="inner">
                                <div className="icon-box-1">
                                    <i className="icon-crain"></i>
                                </div>
                                <div className="content">
                                    <h5><Link to="service-details.html">Science of Building</Link></h5>
                                    <p>We leverage cutting-edge technologies.</p>
                                </div>
                            </div>
                            <div className="inner">
                                <div className="icon-box-1">
                                    <i className="icon-mixer-truck"></i>
                                </div>
                                <div className="content">
                                    <h5><Link to="service-details.html">Engineering Marvels</Link></h5>
                                    <p>We leverage cutting-edge technologies.</p>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-button-area  mt-30">
                            <Link className="theme__button " to="/services">
                                Explore Services <i className="icon-arrow-double-right"></i>
                                
                            </Link>
                            <div className="chat-us">
                                <div className="icon-2">
                                    <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.4058 6.35513C28.1145 4.32519 24.2144 3.20898 20.1855 3.20898C9.21848 3.20898 0 11.3798 0 21.8418C0 25.5099 1.1395 29.0262 3.30401 32.063L0.28032 41.556C-0.0391289 42.5588 0.711981 43.5801 1.76008 43.5801C1.99971 43.5801 2.24091 43.5247 2.46346 43.4115L11.659 38.736C12.0313 38.8963 12.4083 39.0455 12.7891 39.184C10.6601 35.8609 9.52344 32.0464 9.52344 28.0527C9.52344 16.1591 19.5311 7.01546 31.4058 6.35513Z" fill="#FF8F27"/>
                                    <path d="M49.696 38.274C51.8605 35.2371 53 31.7208 53 28.0527C53 17.5869 43.7776 9.41992 32.8145 9.41992C21.8474 9.41992 12.6289 17.5907 12.6289 28.0527C12.6289 38.5186 21.8513 46.6855 32.8145 46.6855C35.7561 46.6855 38.6871 46.0861 41.3405 44.9467L50.5365 49.6224C51.0946 49.9061 51.7687 49.8269 52.2458 49.4215C52.7229 49.016 52.9098 48.3636 52.7198 47.7671L49.696 38.274ZM26.5 29.6055C25.6425 29.6055 24.9473 28.9103 24.9473 28.0527C24.9473 27.1952 25.6425 26.5 26.5 26.5C27.3575 26.5 28.0527 27.1952 28.0527 28.0527C28.0527 28.9103 27.3575 29.6055 26.5 29.6055ZM32.7109 29.6055C31.8534 29.6055 31.1582 28.9103 31.1582 28.0527C31.1582 27.1952 31.8534 26.5 32.7109 26.5C33.5685 26.5 34.2637 27.1952 34.2637 28.0527C34.2637 28.9103 33.5685 29.6055 32.7109 29.6055ZM38.9219 29.6055C38.0644 29.6055 37.3691 28.9103 37.3691 28.0527C37.3691 27.1952 38.0644 26.5 38.9219 26.5C39.7794 26.5 40.4746 27.1952 40.4746 28.0527C40.4746 28.9103 39.7794 29.6055 38.9219 29.6055Z" fill="#1F212D"/>
                                    </svg>                           
                                </div>
                                <div className="content p-relative z-1">
                                    <span>Chat Us Anytime</span>
                                    <h5><Link to="tel:4065550120">(406) 555-0120</Link></h5>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default AboutThree;
