import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/bg/map-bg.png";
import img2 from "../../../assets/images/team/team-1-1.jpg";
import img3 from "../../../assets/images/team/team-1-2.jpg";
import img4 from "../../../assets/images/team/team-1-3.jpg";
import img5 from "../../../assets/images/team/team-1-4.jpg";
import WOW from 'wowjs';
import CountUp from 'react-countup';

const TeamThree = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (

    <section className="section-space" style={{ backgroundImage: `url(${img1})` }}>
        <div className="custom-container">
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <div className="team-3-content mt-50">
                        <div className="title-box mb-30 wow fadeInLeft" data-wow-delay=".5s">
                        <span className="section-sub-title">Professional Expert</span>
                        <h3 className="section-title mt-10 mb-20">Meet The Team</h3>
                        </div>
                        <div className="counter-box mb-30">
                        <div className="icon-box mb-10">
                            <i className="icon-left-angle"></i>
                        </div>
                        <h2><CountUp className="odometer" start={0} end={30} />+</h2>
                        <h5>Our Total Member</h5>
                        </div>
                        <p className="mb-30">Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet.</p>
                        <ul className="team-3-list mb-40">
                        <li>The Art and Science of Building</li>
                        <li>Mastering the Construction Process</li>
                        <li>Green Construction Practices</li>
                        </ul>
                        <Link className="theme__button " to="/team">
                        View Team Members <i className="icon-arrow-double-right"></i>
                        
                        </Link>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="team-3-area">
                        <div className="row g-4">
                            <div className="col-lg-6 mb-15">
                                <div className="team-area-box p-relative">
                                    <figure className="image w-img">
                                        <img src={img2} alt="" />
                                    </figure>
                                    <div className="content">
                                        <div>
                                        <h5><Link to="/team-details">Esther Howard</Link></h5>
                                        <span>Consultant</span>
                                        </div>
                                        <div className="socials-media-area p-relative">
                                        <i className="icon-share"></i>
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-15">
                                <div className="team-area-box p-relative">
                                    <figure className="image w-img">
                                        <img src={img3} alt="" />
                                    </figure>
                                    <div className="content">
                                        <div>
                                        <h5><Link to="/team-details">Michel Howard</Link></h5>
                                        <span>Consultant</span>
                                        </div>
                                        <div className="socials-media-area p-relative">
                                        <i className="icon-share"></i>
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-15">
                                <div className="team-area-box p-relative">
                                    <figure className="image w-img">
                                        <img src={img4} alt="" />
                                    </figure>
                                    <div className="content">
                                        <div>
                                        <h5><Link to="/team-details">Mona Tonaw</Link></h5>
                                        <span>Consultant</span>
                                        </div>
                                        <div className="socials-media-area p-relative">
                                        <i className="icon-share"></i>
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-15">
                                <div className="team-area-box p-relative">
                                    <figure className="image w-img">
                                        <img src={img5} alt="" />
                                    </figure>
                                    <div className="content">
                                        <div>
                                        <h5><Link to="/team-details">Lily Flow</Link></h5>
                                        <span>Consultant</span>
                                        </div>
                                        <div className="socials-media-area p-relative">
                                        <i className="icon-share"></i>
                                        <ul>
                                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  );
};

export default TeamThree;
