import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/banner/crain.png";
import img2 from "../../../assets/images/bg/banner-bg.png";
import img3 from "../../../assets/images/shapes/shape-10.png";
import img4 from "../../../assets/images/banner/banner-1.png";
import img5 from "../../../assets/images/banner/buldrozer.png";
import img6 from "../../../assets/images/shapes/shape-1.png";
import WOW from 'wowjs';

const BannerOne = () => {
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

    
  return (

    <>
       <section className="banner-section p-relative bg-color-1 fix">
          <div className="image-1 wow fadeInLeft" style={{ backgroundImage: `url(${img1})` }}></div>
          <div className="shape wow fadeInRight" data-wow-delay="700ms" style={{ backgroundImage: `url(${img2})` }}></div>
          <div className="container-fluid g-0">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="banner-content p-relative z-2 wow img-custom-anim-left" data-wow-delay="700ms">
                      <h1 className="banner-title">Construction & Building Agency</h1>
                      <p className="banner-text">Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                      <div className="banner-link">
                          <Link className="theme__button " to="/services">
                            Explore Services <i className="icon-arrow-double-right"></i>
                            
                          </Link>
                      </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="image-3 w-img" style={{ maskImage: `url(${img3})`, WebkitMaskImage: `url(${img3})` }}>
                      <img className="image" src={img4} alt="" />
                    </div>
                </div>
              </div>
          </div>
        </section>
        <div className="extra-image-container p-relative">
          <div className="extra-image text-center wow im float-bob-x" data-wow-delay="3000ms">
              <img src={img5} alt="" />
            </div>
          <div className="extra-image-2 wow im float-bob-x" data-wow-delay="3000ms">
              <img src={img6} alt="" />
            </div>
        </div>  
    </>
  
  );
};

export default BannerOne;
