import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/banner/banner-3.jpg";
import img2 from "../../../assets/images/shapes/shape-22.png";
import img3 from "../../../assets/images/shapes/shape-23.png";
import img4 from "../../../assets/images/banner/banner-4.jpg";
import WOW from 'wowjs';

const BannerThree = () => {
      useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

     

  return (
    <>
        <section className="banner-3-section fix" style={{ backgroundImage: `url(${img1})` }}>
          <h1 className="vertical-text">Buildsphere</h1>
          <div className="shape-1" style={{ backgroundImage: `url(${img2})` }}></div>
          <div className="large-container">
              <div className="banner-3-container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6">
                      <div className="banner-3-content-area p-relative z-1 img-custom-anim-left">
                          <h2>Best Service For</h2>
                          <h1><span>Building</span> Agency</h1>
                          <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. </p>
                          <div className="banner-3-btn">
                            <Link className="theme__button " to="/services">
                                Explore Services <i className="icon-arrow-double-right"></i>
                                
                            </Link>
                          </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6">
                      <div className="banner-3-image-area p-relative wow fadeInRight" data-wow-delay="700ms">
                          <div className="shape-2" style={{ backgroundImage: `url(${img3})` }}></div>
                          <figure className="w-img ">
                            <img src={img4} alt="" />
                          </figure>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </section>
    </>
  );
};

export default BannerThree;
