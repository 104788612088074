import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/shapes/shape-1.png";
import img2 from "../../../assets/images/brand/brand-1.png";
import img3 from "../../../assets/images/brand/brand-2.png";
import img4 from "../../../assets/images/brand/brand-3.png";
import img5 from "../../../assets/images/brand/brand-4.png";

import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import WOW from 'wowjs';

const FunfactThree = () => {


    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
    
    }, [])
  
    const options = {
        autoplay: true,
        smartSpeed: 2000,
        margin: 50,
        loop: true,
        items: 4,
        autoplayHoverPause: true,
        dots: true,
        navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
        nav: true, 
        responsive: {
          0: {
              items: 1,
          },
          400: {
              items: 1,
          },
          600: {
              items: 2,
          },
          700: {
              items: 2,
          },
          1000: {
              items: 3,
          },
          1200: {
              items: 4,
          },
          1400: {
              items: 4,
          },
          1600: {
              items: 4,
          },
         },
      };

  return (

    <section className="em-construction-section p-relative bg-color-3">
        <div className="crane-shape float-bob-x" style={{ backgroundImage: `url(${img1})` }}></div>
        <div className="custom-container">
            <div className="row">
                <div className="col-lg-3 col-md-12">
                    <div className="em-main-box">
                    <div className="content p-relative">
                        <div className="inner">
                            <div className="icon-box">
                                <i className="icon-cross"></i>
                            </div>
                            <h4><CountUp className="odometer" start={0} end={24} /> + Years </h4>
                            <h5>Experience</h5>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-12">
                    <div className="em-construction-content mt-60 mb-70 ml-50 mr-100">
                    <div className="title-box mb-40 wow fadeInRight" data-wow-delay=".5s">
                        <h3 className="section-title mt-10 mb-20">Engineering Marvels in <br/> Construction</h3>
                        <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
                    </div>
                    <div className="brand-1 mb-30 text-center">
                        <ReactOwlCarousel className="brand-active owl-carousel" {...options}>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img2} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img3} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img4} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img5} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img2} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img3} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img4} alt="" /></Link></span>
                            </div>
                            <div className="swiper_slide"> 
                                <span><Link to="#"><img src={img5} alt="" /></Link></span>
                            </div>
                        </ReactOwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
  );
};

export default FunfactThree;
