import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/projects/project-1.jpg";
import img2 from "../../../assets/images/projects/project-2.jpg";
import img3 from "../../../assets/images/projects/project-3.jpg";
import img4 from "../../../assets/images/projects/project-4.jpg";
import img5 from "../../../assets/images/projects/project-5.jpg";
import WOW from 'wowjs';

const ProjectsOne = () => {   
  
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
  

  return (

    <section className="project-section section-space">
        <div className="custom-container">
        <div className="title-box text-center mb-60 wow fadeInLeft" data-wow-delay=".5s">
            <span className="section-sub-title">Our Projects</span>
            <h3 className="section-title mt-10 mb-25">Explore Our Latest <br/>Projects Works</h3>
            <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
        </div>
        <div className="row g-0">
            <div className="col-xxl-4 col-xxl-4 col-lg-4 wow fadeInRight" data-wow-delay=".5s">
                <div className="project-box-area p-relative">
                    <figure className="image w-img">
                    <img src={img1} alt="" />
                    </figure>
                    <div className="content">
                    <Link className="project-btn" to="/projects-details"><span>View More</span></Link>
                    <h2>#1</h2>
                    <h4><Link to="/projects-details">House Renovation</Link></h4>
                    </div>
                </div>
            </div>
            <div className="col-xxl-4 col-xxl-4 col-lg-4 wow fadeInDown" data-wow-delay=".5s">
                <div className="project-box-area p-relative">
                    <figure className="image w-img">
                    <img src={img2} alt="" />
                    </figure>
                    <div className="content">
                    <Link className="project-btn" to="/projects-details"><span>View More</span></Link>
                    <h2>#2</h2>
                    <h4><Link to="/projects-details">House Renovation</Link></h4>
                    </div>
                </div>
            </div>
            <div className="col-xxl-4 col-xxl-4 col-lg-4 wow fadeInLeft" data-wow-delay=".5s">
                <div className="project-box-area p-relative">
                    <figure className="image w-img">
                    <img src={img3} alt="" />
                    </figure>
                    <div className="content">
                    <Link className="project-btn" to="/projects-details"><span>View More</span></Link>
                    <h2>#3</h2>
                    <h4><Link to="/projects-details">House Renovation</Link></h4>
                    </div>
                </div>
            </div>
            <div className="col-xxl-4 col-xxl-4 col-lg-4 wow fadeInRight" data-wow-delay=".7s">
                <div className="project-box-area p-relative">
                    <figure className="image w-img">
                    <img src={img4} alt="" />
                    </figure>
                    <div className="content">
                    <Link className="project-btn" to="/projects-details"><span>View More</span></Link>
                    <h2>#4</h2>
                    <h4><Link to="/projects-details">House Renovation</Link></h4>
                    </div>
                </div>
            </div>
            <div className="col-xxl-8 col-xxl-8 col-lg-8 wow fadeInLeft" data-wow-delay=".7s">
                <div className="project-box-area p-relative">
                    <figure className="image w-img">
                    <img src={img5} alt="" />
                    </figure>
                    <div className="content">
                    <Link className="project-btn" to="/projects-details"><span>View More</span></Link>
                    <h2>#5</h2>
                    <h4><Link to="/projects-details">House Renovation</Link></h4>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
  
  );
};

export default ProjectsOne;
