import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import img1 from "../../../assets/images/shapes/shape-13.png";
import img2 from "../../../assets/images/shapes/shape-14.png";
import img3 from "../../../assets/images/services/services-2-2.jpg";
import img4 from "../../../assets/images/services/services-2-3.jpg";
import img5 from "../../../assets/images/services/services-2-4.jpg";
import img6 from "../../../assets/images/services/services-2-5.jpg";
import WOW from 'wowjs';

const ServicesTwo = () => {

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, []);

    const options = {
        autoplay: true,
        smartSpeed: 2000,
        margin: 0,
        loop: true,
        items: 4,
        autoplayHoverPause: true,
        dots: true,
        navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
        nav: true, 
        responsive: {
          0: {
              items: 1,
          },
          400: {
              items: 1,
          },
          600: {
              items: 2,
          },
          700: {
              items: 2,
          },
          1000: {
              items: 3,
          },
          1200: {
              items: 4,
          },
          1400: {
              items: 4,
          },
          1600: {
              items: 4,
          },
      },
      };

    // Define services data
    const services = [
        {
            imageSrc: img3,
            alt: "Service 1",
            title: "Structural Engineering",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img4,
            alt: "Service 2",
            title: "House Renovation",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img5,
            alt: "Service 3",
            title: "Material Supply",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img6,
            alt: "Service 4",
            title: "Welding & Laser",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img3,
            alt: "Service 1",
            title: "Structural Engineering",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img4,
            alt: "Service 2",
            title: "House Renovation",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img5,
            alt: "Service 3",
            title: "Material Supply",
            description: "We leverage cutting-edgestae technologies"
        },
        {
            imageSrc: img6,
            alt: "Service 4",
            title: "Welding & Laser",
            description: "We leverage cutting-edgestae technologies"
        }
    ];

    return (
        <section className="service-2-section p-relative fix section-space-bottom">
            <div className="shape-car" style={{ backgroundImage: `url(${img1})` }}></div>
            <div className="shape-ring" style={{ backgroundImage: `url(${img2})` }}></div>
            <div className="large-container">
                <div className="service-2-main bg-color-3 section-space-top pb-80">
                    <div className="title-box text-center mb-60 wow fadeInLeft" data-wow-delay=".5s">
                        <span className="section-sub-title">What We Provide</span>
                        <h3 className="section-title mt-10 mb-25">We Provide Best Quality <br />Construction Service</h3>
                        <p>We leverage cutting-edge technologies to create compelling content <br />across various digital platforms.</p>
                    </div>
                    <div className="custom-container">
                        <ReactOwlCarousel className="service-active-2 owl-carousel" {...options}>
                            {services.map((service, index) => (
                                <div className="swiper_slide" key={index}>
                                    <div className="service-2-slide-area p-relative">
                                        <Link className="image w-img" to="/service-details">
                                            <img src={service.imageSrc} alt={service.alt} />
                                        </Link>
                                        <div className="content text-center p-relative">
                                            <div className="icon-box">
                                                <i className="icon-saw"></i>
                                            </div>
                                            <h5><Link to="/service-details">{service.title}</Link></h5>
                                            <p>{service.description}</p>
                                            <Link className="btn-box" to="/service-details">
                                                <i className="icon-right-arrow"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesTwo;
