import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Home, Pages, Services, Projects, News } from "./Menu";
import DropDown from "./DropDown";
import Logo2 from "../../assets/images/logo/logo-white.svg";
import Logo from "../../assets/images/logo/logo.svg";
import img1 from "../../assets/images/bg/header-bg.png";
import img2 from "../../assets/images/bg/header-bg-1.png";

export default function Header({ variant }) {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        });
    }, []);

    const [isSearchAreaOpened, setIsSearchAreaOpened] = useState(false);
    const [isBodyOverlayOpened, setIsBodyOverlayOpened] = useState(false);

    const handleBodyOverlayClick = () => {
        setIsSearchAreaOpened(false);
        setIsBodyOverlayOpened(false);
    };

    const handleSearchToggleClick = () => {
        setIsSearchAreaOpened(true);
        setIsBodyOverlayOpened(true);
    };

    const handleSearchCloseClick = () => {
        setIsSearchAreaOpened(false);
        setIsBodyOverlayOpened(false);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleOffCanvas = () => {
        setIsOpen(!isOpen);
    };

    const closeOffCanvas = () => {
        setIsOpen(false);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      };

    
    return (
        <>  
             <div className={`vw-search-area ${isSearchAreaOpened ? 'opened' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                        <div className="vw-search-form">
                            <form action="contact" method="post" onSubmit={handleSubmit}>
                                <div className="vw-search-input">
                                    <input type="text" placeholder="Type your keyword & hit the enter button..." />
                                    <button type="submit"><i className="icon-search"></i></button>
                                </div>
                            
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="vw-search-close text-center mb-20">
                    <button className="vw-search-close-btn vw-search-close-btn" onClick={handleSearchCloseClick}>
                        
                    </button>
                </div>
            </div>
          
            {isBodyOverlayOpened && (
                <div className="body-overlay" onClick={handleBodyOverlayClick}></div>
            )}
           
            
            <div className="fix">
                <div className={`offcanvas__info ${isOpen ? 'info-open' : ''}`}>
                    <div className="offcanvas__wrapper">
                        <div className="offcanvas__content">
                            <div className="offcanvas__top mb-40 d-flex justify-content-between align-items-center">
                                <div className="offcanvas__logo">
                                    <Link to="/">
                                        <img src={Logo2} alt="Header Logo" />
                                    </Link>
                                </div>
                                <div className="offcanvas__close">
                                    <button onClick={toggleOffCanvas}> 
                                        <i className="fal fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="offcanvas__search mb-25">
                                <p className="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum maxime accusamus corrupti natus obcaecati vitae dignissimos, id officiis similique eos.</p>
                            </div>
                            <div className="mobile-menu fix mb-40 mean-container">
                                <div className="mean-bar">
                                    <nav className="mean-nav">
                                        <ul>
                                            <li className="has-dropdown active">
                                                <NavLink to="/">Home</NavLink>
                                                <DropDown>
                                                    <ul className="submenu">
                                                        <Home />
                                                    </ul>
                                                </DropDown>
                                            </li>                             
                                            <li className="has-dropdown">
                                                <NavLink to="/">Pages</NavLink>
                                                <DropDown>
                                                    <ul className="submenu">
                                                        <Pages />
                                                    </ul>
                                                </DropDown>
                                            </li>
                                            <li className="has-dropdown">
                                                <NavLink to="/">Services</NavLink>
                                                <DropDown>
                                                    <ul className="submenu">
                                                        <Services />
                                                    </ul>
                                                </DropDown>
                                            </li> 
                                            <li className="has-dropdown">
                                                <NavLink to="/">Projects</NavLink>
                                                <DropDown>
                                                    <ul className="submenu">
                                                        <Projects />
                                                    </ul>
                                                </DropDown>
                                            </li> 
                                            <li className="has-dropdown">
                                                <NavLink to="/">Blog</NavLink>
                                                <DropDown>
                                                    <ul className="submenu">
                                                        <News />
                                                    </ul>
                                                </DropDown>
                                            </li>
                                            <li>
                                                <NavLink to="/contact">Contact</NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="offcanvas__contact mt-30 mb-20">
                                <h4>Contact Info</h4>
                                <ul>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                        <i className="fal fa-map-marker-alt"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                        <Link target="_blank"
                                            to="#">31 Park End St, Brockhampton, UK</Link>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                        <i className="far fa-phone"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                        <Link to="tel:+00112233665">+00112233665</Link>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                        <i className="fal fa-envelope"></i>
                                        </div>
                                        <div className="offcanvas__contact-text">
                                        <Link to="tel:+012-345-6789"><span className="mailto:support@example.com">support@example.com</span></Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="offcanvas__social">
                                <ul>
                                    <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className={`offcanvas__overlay ${isOpen ? 'overlay-open' : ''}`} onClick={closeOffCanvas}></div>
            <div className="offcanvas__overlay-white"></div>

            <header>
                <div className="large-container header-top-two p-relative">
                    <div className="shape-black" style={{ backgroundImage: `url(${img1})` }}></div>
                    <div className="shape-yellow" style={{ backgroundImage: `url(${img2})` }}></div>
                    <div className="header-top">
                        <div className="header-top-welcome-text">
                        <span className="welcome">Welcome to Construction & Building Company</span>
                        </div>
                        <div className="header-top-contact-info">
                            <span className="mail p-relative"><Link to="mailto:alma.lawson@example.com">alma.lawson@example.com</Link></span>
                            <span className="phone p-relative"><Link to="tel:4065550120">(406) 555-0120</Link></span>
                        </div>
                    </div>
                </div>
                
                <div id="header-sticky" className={`header-area header-style-two ${variant ? variant : ""} header-sticky ${isSticky ? "fixed-header" : ""}`} >
                    <div className="container-fluid">
                        <div className="mega-menu-wrapper">
                        <div className="header-main">               
                            <div className="header-right d-flex justify-content-end">
                                <div className="header-logo">
                                    <Link to="/">
                                        <img src={Logo} alt="header logo" />
                                    </Link>
                                </div>  
                                <div className="menu-with-search-wrapper">
                                    <div className="mean__menu-wrapper d-none d-lg-block">
                                        <div className="main-menu">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li className="has-dropdown active">
                                                        <NavLink to="/">Home</NavLink>
                                                        <DropDown>
                                                            <ul className="submenu">
                                                                <Home />
                                                            </ul>
                                                        </DropDown>
                                                    </li>                             
                                                    <li className="has-dropdown">
                                                        <NavLink to="/">Pages</NavLink>
                                                        <DropDown>
                                                            <ul className="submenu">
                                                                <Pages />
                                                            </ul>
                                                        </DropDown>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <NavLink to="/">Services</NavLink>
                                                        <DropDown>
                                                            <ul className="submenu">
                                                                <Services />
                                                            </ul>
                                                        </DropDown>
                                                    </li> 
                                                    <li className="has-dropdown">
                                                        <NavLink to="/">Projects</NavLink>
                                                        <DropDown>
                                                            <ul className="submenu">
                                                                <Projects />
                                                            </ul>
                                                        </DropDown>
                                                    </li> 
                                                    <li className="has-dropdown">
                                                        <NavLink to="/">Blog</NavLink>
                                                        <DropDown>
                                                            <ul className="submenu">
                                                                <News />
                                                            </ul>
                                                        </DropDown>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/contact">Contact</NavLink>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <div className="header__hamburger ml-50 d-none">
                                                <button type="button" className="hamburger-btn offcanvas-open-btn">
                                                    <span>01</span>
                                                    <span>01</span>
                                                    <span>01</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search-toggle-open header-search" onClick={handleSearchToggleClick}>
                                        <div className="search-icon">
                                            <i className="icon-search"></i>
                                        </div>
                                    </div>
                                </div> 
                                <div className="header-action d-xl-inline-flex gap-4">
                                    <div className="header-link">
                                    <Link className="theme__button " to="/contact">
                                        get a quote <i className="icon-arrow-double-right"></i>
                                        
                                    </Link>
                                    </div>
                                    <div className="header__hamburger my-auto">
                                        <div className="sidebar__toggle" onClick={toggleSidebar}>
                                            <button className="bar-icon"> 
                                                <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.3125 4.59375C4.1927 4.59375 4.90625 3.8802 4.90625 3C4.90625 2.1198 4.1927 1.40625 3.3125 1.40625C2.4323 1.40625 1.71875 2.1198 1.71875 3C1.71875 3.8802 2.4323 4.59375 3.3125 4.59375Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M15 4.59375C15.8802 4.59375 16.5938 3.8802 16.5938 3C16.5938 2.1198 15.8802 1.40625 15 1.40625C14.1198 1.40625 13.4062 2.1198 13.4062 3C13.4062 3.8802 14.1198 4.59375 15 4.59375Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M26.6875 4.59375C27.5677 4.59375 28.2812 3.8802 28.2812 3C28.2812 2.1198 27.5677 1.40625 26.6875 1.40625C25.8073 1.40625 25.0938 2.1198 25.0938 3C25.0938 3.8802 25.8073 4.59375 26.6875 4.59375Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M3.3125 13.7812C4.1927 13.7812 4.90625 13.0677 4.90625 12.1875C4.90625 11.3073 4.1927 10.5938 3.3125 10.5938C2.4323 10.5938 1.71875 11.3073 1.71875 12.1875C1.71875 13.0677 2.4323 13.7812 3.3125 13.7812Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M15 13.7812C15.8802 13.7812 16.5938 13.0677 16.5938 12.1875C16.5938 11.3073 15.8802 10.5938 15 10.5938C14.1198 10.5938 13.4062 11.3073 13.4062 12.1875C13.4062 13.0677 14.1198 13.7812 15 13.7812Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M26.6875 13.7812C27.5677 13.7812 28.2812 13.0677 28.2812 12.1875C28.2812 11.3073 27.5677 10.5938 26.6875 10.5938C25.8073 10.5938 25.0938 11.3073 25.0938 12.1875C25.0938 13.0677 25.8073 13.7812 26.6875 13.7812Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M3.3125 22.9688C4.1927 22.9688 4.90625 22.2552 4.90625 21.375C4.90625 20.4948 4.1927 19.7812 3.3125 19.7812C2.4323 19.7812 1.71875 20.4948 1.71875 21.375C1.71875 22.2552 2.4323 22.9688 3.3125 22.9688Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M15 22.9688C15.8802 22.9688 16.5938 22.2552 16.5938 21.375C16.5938 20.4948 15.8802 19.7812 15 19.7812C14.1198 19.7812 13.4062 20.4948 13.4062 21.375C13.4062 22.2552 14.1198 22.9688 15 22.9688Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M26.6875 22.9688C27.5677 22.9688 28.2812 22.2552 28.2812 21.375C28.2812 20.4948 27.5677 19.7812 26.6875 19.7812C25.8073 19.7812 25.0938 20.4948 25.0938 21.375C25.0938 22.2552 25.8073 22.9688 26.6875 22.9688Z" stroke="#707070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>       
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
