import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/projects/project-6.jpg";
import img2 from "../../../assets/images/shapes/shape-19.png";
import img3 from "../../../assets/images/projects/project-7.jpg";
import img4 from "../../../assets/images/projects/project-8.jpg";
import ModalVideo from 'react-modal-video';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const ProjectsTwo = () => {
    const [isOpen, setOpen] = useState(false)

    const options = {
        autoplay: true,
        smartSpeed: 2000,
        margin: 24,
        loop: true,
        items: 4,
        autoplayHoverPause: true,
        dots: true,
        navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
        nav: true, 
        responsive: {
          0: {
              items: 1,
          },
          400: {
              items: 1,
          },
          600: {
              items: 1,
          },
          700: {
              items: 1,
          },
          1000: {
              items: 1,
          },
          1200: {
              items: 2,
          },
          1400: {
              items: 2,
          },
          1600: {
              items: 2,
          },
        },
      };
  return (

    <section className="project-2-section fix">
        <div className="container-fluid g-0">
            <div className="row">
                <div className="col-lg-5 col-md-12">
                    <div className="project-2-video-box p-relative pr-40 wow fadeInDown" data-wow-delay="500ms">
                        <figure className="w-img">
                            <img src={img1} alt="" />
                        </figure>
                        <div className="play-btn">
                            <React.Fragment>
                                <ModalVideo
                                    channel="youtube"
                                    youtube={{ mute: 0, autoplay: 0 }}
                                    isOpen={isOpen}
                                    videoId="L61p2uyiMSo"
                                    onClose={() => setOpen(false)} 
                                />
                                <button className="popup-video" onClick={() => setOpen(true)}>
                                    <div className="video_player_btn">
                                        <i className="icon-arrow_triangle-right"></i>
                                    </div>
                                </button>
                            </React.Fragment>
                        </div>  
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 project-2-content-auto">
                    <div className="project-2-content-area p-relative">
                        <div className="shape-1 float-bob-y" style={{ backgroundImage: `url(${img2})` }}></div>
                        <div className="title-box mb-40 wow fadeInLeft" data-wow-delay=".5s">
                            <span className="section-sub-title">Our Projects</span>
                            <h3 className="section-title mt-10 mb-25">Explore Our Latest <br/>Projects Works</h3>
                            <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
                        </div>
                        <ReactOwlCarousel className="project-active-1 owl-carousel" {...options}>
                            <div className="item slider_block">
                                <div className="project-box-area p-relative">
                                    <figure className="image w-img">
                                        <img src={img3} alt="" />
                                    </figure>
                                    <div className="content">
                                        <Link className="project-btn" to="/projects"><span>View More</span></Link>
                                        <h2>#1</h2>
                                        <h4><Link to="/projects">House Renovation</Link></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="item slider_block">
                                <div className="project-box-area p-relative">
                                    <figure className="image w-img">
                                        <img src={img4} alt="" />
                                    </figure>
                                    <div className="content">
                                        <Link className="project-btn" to="/projects"><span>View More</span></Link>
                                        <h2>#2</h2>
                                        <h4><Link to="/projects">House Renovation</Link></h4>
                                    </div>
                                </div>
                            </div>
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
  );
};

export default ProjectsTwo;
