import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import 'bootstrap/dist/css/bootstrap.min.css';

const Faq = () => {
  pageTitle('Faq');

  // State to manage which accordion is open
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <>
      <HeaderTwo />
      <BreadCrumb />

      <section className="section-space">
        <div className="custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="vw-faq">
                <h3 className="mb-40">Frequently Asked Questions?</h3>
                <div className="accordion" id="accordionExample-st-2">
                  {/* Accordion items */}
                  {faqData.map((faq, index) => (
                    <div className="vw-faq-group" key={index}>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`heading-${index}`}
                        >
                          <button
                            className={`accordion-button ${
                              activeAccordion === index ? '' : 'collapsed'
                            }`}
                            type="button"
                            onClick={() => toggleAccordion(index)}
                            aria-expanded={activeAccordion === index}
                            aria-controls={`collapse-${index}`}
                          >
                            {faq.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse-${index}`}
                          className={`accordion-collapse collapse ${
                            activeAccordion === index ? 'show' : ''
                          }`}
                          aria-labelledby={`heading-${index}`}
                          data-bs-parent="#accordionExample-st-2"
                        >
                          <div className="accordion-body">
                            {faq.answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterTwo />
    </>
  );
};

// Sample FAQ data
const faqData = [
  {
    question: "Where should I incorporate my business?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  },
  {
    question: "Can I offer my items for free on a promotional basis?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  },
  {
    question: "How can I safely use My Business?",
    answer: "Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id. Pellentesque habitant morbi tristique senectus et netus et malesuada fames."
  }
];

export default Faq;
