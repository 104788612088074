import React from 'react';

const TextAnimation = () => {

  return (

    <section className="text-slider-section fix">
        <div className="container-fluid">
            <div className="text-slider-box">
                <div className="slide-box">
                    <h1>Buildsphere</h1>
                </div>
                <div className="slide-box">
                    <h1>Buildsphere</h1>
                </div>
                <div className="slide-box">
                    <h1>Buildsphere</h1>
                </div>
                <div className="slide-box">
                    <h1>Buildsphere</h1>
                </div>
                <div className="slide-box">
                    <h1>Buildsphere</h1>
                </div>
                <div className="slide-box">
                    <h1>Buildsphere</h1>
                </div>
            </div>
        </div>
    </section>

  
  );
};

export default TextAnimation;
