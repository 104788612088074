import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/team/team-1.jpg";
import img2 from "../../../assets/images/team/team-2.jpg";
import img3 from "../../../assets/images/team/team-3.jpg";
import img4 from "../../../assets/images/bg/team-bg.png";
import WOW from 'wowjs';

const TeamTwo = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (

    <section class="team-2-section p-relative fix section-space" style={{ backgroundImage: `url(${img4})` }}>
        <div class="custom-container">
            <div class="title-box text-center mb-60 wow fadeInLeft" data-wow-delay=".5s">
                <span class="section-sub-title">Professional Expert</span>
                <h3 class="section-title mt-10 mb-25">Meet The Team</h3>
            </div>
            <div class="row g-4">
                <div class="col-xxl-4 col-xl-4 col-lg-4">
                    <div class="team-area-box p-relative">
                        <figure class="image w-img">
                            <img src={img1} alt="" />
                        </figure>
                        <div class="content">
                            <div>
                                <h5><Link to="/team-details">Esther Howard</Link></h5>
                                <span>Consultant</span>
                            </div>
                            <div class="socials-media-area p-relative">
                                <i class="icon-share"></i>
                                <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4">
                    <div class="team-area-box p-relative">
                        <figure class="image w-img">
                            <img src={img2} alt="" />
                        </figure>
                        <div class="content">
                            <div>
                                <h5><Link to="/team-details">Jenny Wilson</Link></h5>
                                <span>Consultant</span>
                            </div>
                            <div class="socials-media-area p-relative">
                                <i class="icon-share"></i>
                                <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4">
                    <div class="team-area-box p-relative">
                        <figure class="image w-img">
                            <img src={img3} alt="" />
                        </figure>
                        <div class="content">
                            <div>
                                <h5><Link to="/team-details">Kaka Hawkins</Link></h5>
                                <span>Consultant</span>
                            </div>
                            <div class="socials-media-area p-relative">
                                <i class="icon-share"></i>
                                <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  );
};

export default TeamTwo;
