import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/bg/get-bg.jpg";
import img2 from "../../../assets/images/shapes/shape-20.png";
import img3 from "../../../assets/images/shapes/shape-21.png";
import WOW from 'wowjs';

const GetContact = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (

    <section className="get-in-touch p-relative section-space" style={{ backgroundImage: `url(${img1})` }}>
        <div className="shape-1 float-bob-x" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="shape-2" style={{ backgroundImage: `url(${img3})` }}></div>
        <div className="custom-container">
            <div className="inner p-relative">
                <div className="title-box wow fadeInLeft" data-wow-delay=".5s">
                    <span className="section-sub-title">Get In Touch</span>
                    <h3 className="section-title mt-10 mb-25">Few Reasons Why You Choose Us Protect Yourself.</h3>
                </div>
                <Link className="theme__button " to="/contact">
                    Contact Us <i className="icon-arrow-double-right"></i>
                    
                </Link>
            </div>
        </div>
    </section>

  );
};

export default GetContact;
