import React from 'react';
import { pageTitle } from '../PageTitle';
import { Link } from 'react-router-dom';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import img1 from "../../assets/images/team/team-1-1.jpg";
import img2 from "../../assets/images/team/team-1-2.jpg";
import img3 from "../../assets/images/team/team-1-3.jpg";
import img4 from "../../assets/images/team/team-1-4.jpg";
import img5 from "../../assets/images/team/team-1.jpg";
import img6 from "../../assets/images/team/team-2.jpg";


const Team = () => {
      pageTitle('Team');
      
  return (
    
    <>

        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>

        <section class="team-page-section section-space">
            <div class="custom-container">
                <div class="team-3-area">
                    <div class="row g-4">
                        <div class="col-lg-6 mb-15">
                            <div class="team-area-box p-relative">
                                <figure class="image w-img">
                                    <img src={img1} alt="" />
                                </figure>
                                <div class="content">
                                    <div>
                                        <h5><Link to="/team-details">Esther Howard</Link></h5>
                                        <span>Consultant</span>
                                    </div>
                                    <div class="socials-media-area p-relative">
                                        <i class="icon-share"></i>
                                        <ul>
                                        <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                        <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-15">
                        <div class="team-area-box p-relative">
                            <figure class="image w-img">
                                <img src={img2} alt="" />
                            </figure>
                            <div class="content">
                                <div>
                                    <h5><Link to="/team-details">Michel Howard</Link></h5>
                                    <span>Consultant</span>
                                </div>
                                <div class="socials-media-area p-relative">
                                    <i class="icon-share"></i>
                                    <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-6 mb-15">
                        <div class="team-area-box p-relative">
                            <figure class="image w-img">
                                <img src={img3} alt="" />
                            </figure>
                            <div class="content">
                                <div>
                                    <h5><Link to="/team-details">Mona Tonaw</Link></h5>
                                    <span>Consultant</span>
                                </div>
                                <div class="socials-media-area p-relative">
                                    <i class="icon-share"></i>
                                    <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-6 mb-15">
                        <div class="team-area-box p-relative">
                            <figure class="image w-img">
                                <img src={img4} alt="" />
                            </figure>
                            <div class="content">
                                <div>
                                    <h5><Link to="/team-details">Lily Flow</Link></h5>
                                    <span>Consultant</span>
                                </div>
                                <div class="socials-media-area p-relative">
                                    <i class="icon-share"></i>
                                    <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-6 mb-15">
                        <div class="team-area-box p-relative">
                            <figure class="image w-img">
                                <img src={img5} alt="" />
                            </figure>
                            <div class="content">
                                <div>
                                    <h5><Link to="/team-details">Esther Howard</Link></h5>
                                    <span>Consultant</span>
                                </div>
                                <div class="socials-media-area p-relative">
                                    <i class="icon-share"></i>
                                    <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-6 mb-15">
                        <div class="team-area-box p-relative">
                            <figure class="image w-img">
                                <img src={img6} alt="" />
                            </figure>
                            <div class="content">
                                <div>
                                    <h5><Link to="team-details">Michel Howard</Link></h5>
                                    <span>Consultant</span>
                                </div>
                                <div class="socials-media-area p-relative">
                                    <i class="icon-share"></i>
                                    <ul>
                                    <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-linkedin-in"></i></Link></li>
                                    <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterTwo></FooterTwo>
    </>
  );
};

export default Team;