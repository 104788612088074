import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import CountUp from 'react-countup';

const GetContactThree = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

      const SubmitHandler = (e) => {
        e.preventDefault()
    }

  return (

    <section className="best-3-company-section bg-color-3 section-space">
        <div className="custom-container">
            <div className="row">
                <div className="col-lg-5 col-md-12">
                    <div className="need-3-form">
                    <div className="title-box wow mb-30 fadeInLeft" data-wow-delay=".5s">
                        <span className="section-sub-title">Need Any Help</span>
                        <h4 className="mt-10">Get Services Available</h4>
                    </div>
                    <form id="form" method="post" onSubmit={SubmitHandler}>
                        <div className="row">
                            <div className="col-lg-12">
                                <input id="name" name="name" type="text" placeholder="Name" />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <input id="email" name="email" type="email" placeholder="Email*" required="" />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <input type="tel" placeholder="Phone" required="" />
                            </div>
                            <div className="col-lg-12">
                                <textarea id="message" name="message" placeholder="Message*" required></textarea>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="theme__button ">
                                Send Now <i className="icon-arrow-double-right"></i>
                                
                                </button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12">
                    <div className="best-3-com">
                    <div className="title-box mb-40 wow fadeInLeft" data-wow-delay=".5s">
                        <span className="section-sub-title">Trusted Best Company</span>
                        <h3 className="section-title mt-10 mb-20">We Help You Build On Your Past & Future.</h3>
                        <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus purus, in congue nunc venenatis id.</p>
                    </div>
                    <div className="counter-area">
                        <div className="counter-box">
                            <div className="icon-box mb-10">
                                <i className="icon-left-angle"></i>
                            </div>
                            <h2><CountUp className="odometer" start={0} end={230} />+</h2>
                            <h5>Project Complete</h5>
                        </div>
                        <div className="counter-box">
                            <div className="icon-box mb-10">
                                <i className="icon-left-angle"></i>
                            </div>
                            <h2><CountUp className="odometer" start={0} end={150} />+</h2>
                            <h5>Quality Team</h5>
                        </div>
                        <div className="counter-box">
                            <div className="icon-box mb-10">
                                <i className="icon-left-angle"></i>
                            </div>
                            <h2><CountUp className="odometer" start={0} end={20} />+</h2>
                            <h5>Years Of Experience</h5>
                        </div>
                    </div>
                    <div className="yellow-box mt-60">
                        <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus</p>
                        <Link className="btn-box" to="/services">
                            <span>Our Services</span>
                            <i className="fas fa-circle-arrow-up-right"></i>
                        </Link>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  );
};

export default GetContactThree;
