import { Fragment } from "react";
import { Link } from "react-router-dom";

export const Home = () => (
    <Fragment>
        <li><Link to="/">Home One</Link></li>
        <li><Link to="/home-two">Home Two</Link></li>
        <li><Link to="/home-three">Home Three</Link></li>
    </Fragment>
);
export const Pages = () => (
    <Fragment>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/team">Team</Link></li>
        <li><Link to="/team-details">Team Details</Link></li>
        <li><Link to="/testimonials">Testimonials</Link></li>
        <li><Link to="/faq">Faq's</Link></li>
        <li><Link to="/error">Error Page</Link></li>
    </Fragment>
);
export const Services = () => (
    <Fragment>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/service-details">Service Details</Link></li>
    </Fragment>
);
export const Projects = () => (
    <Fragment>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/projects-details">Projects Details</Link></li>
    </Fragment>
);
export const News = () => (
    <Fragment>
        <li><Link to="/blog-right-sidebar">Blog Right Sidebar</Link></li>
        <li><Link to="/blog-left-sidebar">Blog Left Sidebar</Link></li>
        <li><Link to="/blog-fullwidth">Blog Fullwidth</Link></li>
        <li><Link to="/blog-details">Blog Single</Link></li>
        <li><Link to="/blog-details-left-sidebar">Blog Single Left</Link></li>
        <li><Link to="/blog-details-fullwidth">Blog Single Fullwidth</Link></li>
    </Fragment>
);
