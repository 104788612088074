import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";

import img1 from "../../assets/images/blogs/blog-1-2.jpg";
import img2 from "../../assets/images/blogs/blog-1.jpg";
import img3 from "../../assets/images/blogs/blog-2.jpg";
import img4 from "../../assets/images/team/team-1.jpg";
import img5 from "../../assets/images/team/team-2.jpg";
import img6 from "../../assets/images/team/team-3.jpg";
import img7 from "../../assets/images/blogs/blog-4.jpg";
import img8 from "../../assets/images/blogs/blog-5.jpg";
import img9 from "../../assets/images/blogs/blog-6.jpg";

const BlogDetails = () => {
      pageTitle('Blog Details');

      const SubmitHandler = (e) => {
        e.preventDefault()
    }

  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>
       
        <section className="postbox__area section-space">
            <div className="container">
                <div className="row gy-50">
                    <div className="col-xxl-8 col-lg-8">
                        <div className="postbox__wrapper theme-bg-2">
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img1} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6667 13V11.6667C11.6667 10.9594 11.3857 10.2811 10.8856 9.78105C10.3855 9.28095 9.70724 9 9 9H3.66667C2.95942 9 2.28115 9.28095 1.78105 9.78105C1.28095 10.2811 1 10.9594 1 11.6667V13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M6.33317 6.33333C7.80593 6.33333 8.99984 5.13943 8.99984 3.66667C8.99984 2.19391 7.80593 1 6.33317 1C4.86041 1 3.6665 2.19391 3.6665 3.66667C3.6665 5.13943 4.86041 6.33333 6.33317 6.33333Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg> January 02, 2024
                                    </span>
                                    <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9718 6.66668C12.9741 7.54659 12.769 8.4146 12.3732 9.20001C11.9039 10.1412 11.1825 10.9328 10.2897 11.4862C9.39697 12.0396 8.36813 12.3329 7.31844 12.3333C6.4406 12.3356 5.57463 12.13 4.79106 11.7333L1 13L2.26369 9.20001C1.86791 8.4146 1.66281 7.54659 1.6651 6.66668C1.66551 5.61452 1.95815 4.58325 2.51025 3.68838C3.06236 2.79352 3.85211 2.0704 4.79106 1.60002C5.57463 1.20331 6.4406 0.997725 7.31844 1.00002H7.65099C9.03729 1.07668 10.3467 1.66319 11.3284 2.64726C12.3102 3.63132 12.8953 4.94378 12.9718 6.33334V6.66668Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>5
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="blog-details.html">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                            </div>
                        </article>
                        <div className="postbox__features">
                            <h4 className="postbox__features-title mb-20">We love what we &amp; like creating new things </h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit
                                urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
                                Maecenas vitae mattis tellus. Nullam quis imperdiet augue. </p>
                            <div className="postbox__thumb-wrapper p-relative mt-30 mb-30">
                                <div className="row g-5">
                                    <div className="col-lg-6">
                                    <div className="postbox__features-thumb w-img">
                                        <img src={img2} alt="" />
                                    </div>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="postbox__features-thumb w-img">
                                        <img src={img3} alt="" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="postbox__quote mb-35">
                            <blockquote>
                                <p>“I try as much as possible to give you a great basic product and what comes out, I feel,
                                    is really amazing.”</p>
                                <div className="blog__quote-author d-flex justify-content-end">
                                    <div className="quote__author-info">
                                    <h4>Yahia Lelil</h4>
                                    <span>Designer</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        <div className="postbox__share-wrapper mb-60">
                            <div className="row align-items-center">
                                <div className="col-xl-7">
                                    <div className="tagcloud tagcloud-sm">
                                    <span>Tags:</span>
                                    <Link to="#">Blog</Link>
                                    <Link to="#">Creative</Link>
                                    <Link to="#">Design</Link>
                                    <Link to="#">WordPress</Link>
                                    </div>
                                </div>
                                <div className="col-xl-5">
                                    <div className="postbox__share text-xl-end">
                                    <span>Share On:</span>
                                    <Link to="#"><i className="fa-brands fa-linkedin-in"></i></Link>
                                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                                    <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="postbox__comment-wrapper">
                            <div className="postbox__comment mb-60">
                                <h3 className="postbox__comment-title">Comments (2)</h3>
                                <ul>
                                    <li>
                                    <div className="postbox__comment-box d-sm-flex align-items-start">
                                        <div className="postbox__comment-info">
                                            <div className="postbox__comment-avatar">
                                                <img src={img4} alt="" />
                                            </div>
                                        </div>
                                        <div className="postbox__comment-text ">
                                            <div className="postbox__comment-name">
                                                <span className="post-meta"> December 14, 2023</span>
                                                <h5><Link to="#">Evab Jam</Link></h5>
                                            </div>
                                            <p>Use high-quality images and videos to create a visually.
                                                Everything you needs guys, buy it you won’t regret it!</p>
                                            <div className="postbox__comment-reply">
                                                <Link to="#">Reply</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="children">
                                        <li>
                                            <div className="postbox__comment-box d-sm-flex align-items-start">
                                                <div className="postbox__comment-info">
                                                <div className="postbox__comment-avatar">
                                                    <img src={img5} alt="" />
                                                </div>
                                                </div>
                                                <div className="postbox__comment-text ">
                                                <div className="postbox__comment-name">
                                                    <span className="post-meta"> December 14, 2023</span>
                                                    <h5><Link to="#">Herculis Marz</Link></h5>
                                                </div>
                                                <p>Use high-quality images and videos to create a visually.
                                                    Everything you needs guys, buy it you won’t regret it! </p>
                                                <div className="postbox__comment-reply">
                                                    <Link to="#">Reply</Link>
                                                </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    <div className="postbox__comment-box d-sm-flex align-items-start">
                                        <div className="postbox__comment-info">
                                            <div className="postbox__comment-avatar">
                                                <img src={img6} alt="" />
                                            </div>
                                        </div>
                                        <div className="postbox__comment-text ">
                                            <div className="postbox__comment-name">
                                                <span className="post-meta"> December 14, 2023</span>
                                                <h5><Link to="#">Yomi Jahe D</Link></h5>
                                            </div>
                                            <p>Use high-quality images and videos to create a visually.
                                                Everything you needs guys, buy it you won’t regret it!</p>
                                            <div className="postbox__comment-reply">
                                                <Link to="#">Reply</Link>
                                            </div>
                                        </div>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="postbox__comment-form">
                                <h3 className="postbox__comment-form-title">Leave A Reply</h3>
                                <p>Your email address will not be published. Required fields are marked *</p>
                                <form method="post" onSubmit={SubmitHandler}>
                                    <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                        <div className="postbox__comment-input">
                                            <input type="text" placeholder="Name*" />
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                        <div className="postbox__comment-input">
                                            <input type="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="postbox__comment-input">
                                            <textarea placeholder="Your Comment Here..."></textarea>
                                        </div>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="postbox__comment-agree d-flex align-items-start mb-25">
                                            <input className="e-check-input" type="checkbox" id="e-agree" />
                                            <label className="e-check-label" htmlFor="e-agree">Save my name, email, and website in
                                                this browser for the next time I comment.</label>
                                        </div>
                                    </div>
                                    <div className="col-xxl-12">
                                        <div className="postbox__comment-btn">
                                            <button type="submit" className="theme__button ">
                                                Submit Now !
                                                
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-lg-4">
                        <div className="sidebar__wrapper vw-sticky pl-30">
                        <div className="sidebar__widget mb-20">
                            <div className="sidebar__widget-content">
                                <div className="sidebar__search">
                                    <form method="post" onSubmit={SubmitHandler}>
                                    <div className="sidebar__search-input">
                                        <input type="text" placeholder="Enter your keywords..." />
                                        <button type="submit">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M19.0002 19.0002L17.2002 17.2002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar__widget mb-45">
                            <h3 className="sidebar__widget-title">Category</h3>
                            <div className="sidebar__widget-content">
                                <ul>
                                    <li><Link to="blog-grid.html">Engineering <span>10</span></Link></li>
                                    <li><Link to="blog-grid.html">House <span>08</span></Link></li>
                                    <li><Link to="blog-grid.html">Material <span>24</span></Link></li>
                                    <li><Link to="blog-grid.html">Welding <span>37</span></Link></li>
                                    <li><Link to="blog-grid.html">Services <span>103</span></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar__widget mb-45">
                            <h3 className="sidebar__widget-title">Recent Post</h3>
                            <div className="sidebar__widget-content">
                                <div className="sidebar__post">
                                    <div className="rc__post d-flex align-items-center">
                                    <div className="rc__post-thumb">
                                        <Link to="blog-details.html">
                                            <img src={img7} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="rc__post-content">
                                        <h4 className="rc__post-title">
                                            <Link to="blog-details.html">We are Best any Industrial & Business Solution</Link>
                                        </h4>
                                        <div className="rc__meta">
                                            <span>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>Jan 21, 2024
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="rc__post d-flex align-items-center">
                                    <div className="rc__post-thumb">
                                        <Link to="blog-details.html">
                                            <img src={img8} alt="" />

                                            </Link>
                                    </div>
                                    <div className="rc__post-content">
                                        <h4 className="rc__post-title">
                                            <Link to="blog-details.html">We are Best any Industrial & Business Solution</Link>
                                        </h4>
                                        <div className="rc__meta">
                                            <span>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>Jan 21, 2024
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="rc__post d-flex align-items-center">
                                    <div className="rc__post-thumb">
                                        <Link to="blog-details.html">
                                            <img src={img9} alt="" />

                                            </Link>
                                    </div>
                                    <div className="rc__post-content">
                                        <h4 className="rc__post-title">
                                            <Link to="blog-details.html">We are Best any Industrial & Business Solution</Link>
                                        </h4>
                                        <div className="rc__meta">
                                            <span>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>Jan 21, 2024
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar__widget mb-40">
                            <h3 className="sidebar__widget-title">Tags</h3>
                            <div className="sidebar__widget-content">
                                <div className="tagcloud">
                                    <Link to="#">Building</Link>
                                    <Link to="#">Construction</Link>
                                    <Link to="#">Design</Link>
                                    <Link to="#">Business</Link>
                                    <Link to="#">Home</Link>
                                    <Link to="#">Trend 2024</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <FooterTwo></FooterTwo>
    </>
  );
};

export default BlogDetails;