import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";
import img1 from "../../assets/images/services/services-2-1.jpg";
import img2 from "../../assets/images/services/services-2-3.jpg";
import img3 from "../../assets/images/services/services-2-2.jpg";
import img4 from "../../assets/images/services/services-2-4.jpg";
import img5 from "../../assets/images/services/services-2-5.jpg";
import img6 from "../../assets/images/services/services-2-6.jpg";
import img7 from "../../assets/images/services/services-2-7.jpg";
import img8 from "../../assets/images/services/services-2-8.jpg";

import GetContact from '../HomeTwo/GetContact/GetContact';
import ServiceThree from '../HomeThree/ServiceThree/ServiceThree';
import GetContactThree from '../HomeThree/GetContactThree/GetContactThree';

const Services = () => {

      pageTitle('Services');

  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>
       
        <section class="service-2-section p-relative fix section-space">
            <div class="large-container">
                <div class="service-3-main">
                    <div class="custom-container">          
                    <div class="row g-4">
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img1} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">Structural <br/>Engineering</Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img2} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">Material <br/>Supply </Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img3} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">House <br/>Renovation </Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img4} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">Welding & <br/> Laser</Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img5} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">Structural <br/>Engineering</Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img6} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">Material <br/>Supply </Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img7} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">House <br/>Renovation </Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-3 col-md-6 mb-15">
                        <div class="service-2-slide-area p-relative">
                            <Link class="image w-img" to="/service-details">
                                <img src={img8} alt="" />
                            </Link>
                            <div class="content text-center p-relative">
                                <div class="icon-box">
                                    <i class="icon-saw"></i>
                                </div>
                                <h5><Link to="/service-details">Welding & <br/> Laser</Link></h5>
                                <p>We leverage cutting-edgestae technologies </p>
                                <Link class="btn-box" to="/service-details">
                                    <i class="icon-right-arrow"></i>
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div>           
                    </div>
                </div>
            </div>
        </section>

        <GetContact></GetContact>
        <ServiceThree></ServiceThree>
        <GetContactThree></GetContactThree>
        <FooterTwo></FooterTwo>
    </>
  );
};

export default Services;