import React, {useEffect} from 'react';
import img1 from "../../../assets/images/bg/feaures-bg-1.jpg";
import img2 from "../../../assets/images/shapes/shape-3.png";
import img3 from "../../../assets/images/shapes/shape-5.png";
import img4 from "../../../assets/images/resources/service-provider-1.png";
import img5 from "../../../assets/images/shapes/shape-4.png";
import WOW from 'wowjs';
import CountUp from 'react-countup';

const ServiceTwo = () => {
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
    return (

        <section className="service-provider-section p-relative">
            <div className="service-provider-container p-relative pt-90 pb-130 fix">
            <div className="bg-image-1" style={{ backgroundImage: `url(${img1})` }}></div>
            <div className="shape-1" style={{ backgroundImage: `url(${img2})` }}></div>
            <div className="custom-container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                        <div className="service-provider-content p-relative wow fadeInRight" data-wow-delay=".5s">
                        <div className="title-box mb-30 wow fadeInLeft" data-wow-delay=".5s">
                            <span className="section-sub-title">amazing industry</span>
                            <h3 className="section-title mt-10 mb-25">Best Industrial Services Provider</h3>
                            <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
                        </div>
                        <div className="counter-area">
                            <div className="counter-box">
                                <div className="icon-box">
                                    <i className="icon-left-angle"></i>
                                </div>
                                <h2><CountUp className="counter" start={0} end={230} />+</h2>
                                <h5>Project Complete</h5>
                            </div>
                            <div className="counter-box">
                                <div className="icon-box">
                                    <i className="icon-left-angle"></i>
                                </div>
                                <h2><CountUp className="counter" start={0} end={20} />+</h2>
                                <h5>Quality Team</h5>
                            </div>
                            <div className="counter-box">
                                <div className="icon-box">
                                    <i className="icon-left-angle"></i>
                                </div>
                                <h2><CountUp className="counter" start={0} end={30} />+</h2>
                                <h5>Years Of Experience</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                        <div className="service-provider-image-area">
                        <div className="image-1" style={{ maskImage: `url(${img3})`, WebkitMaskImage: `url(${img3})` }}>
                            <img src={img4} alt="" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="image-2 wow fadeInRight" data-wow-delay=".5s">
                <img src={img5} alt="" />
            </div>
        </section>

    );
};

export default ServiceTwo;