import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/bg/services-bg.png";
import img2 from "../../../assets/images/shapes/shape-2.png";
import img3 from "../../../assets/images/services/services-1.png";
import img4 from "../../../assets/images/services/services-2.png";
import img5 from "../../../assets/images/services/services-3.png";
import img6 from "../../../assets/images/services/services-4.png";
import WOW from 'wowjs';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Service = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

      const options = {
        autoplay: true,
        smartSpeed: 2000,
        margin: 0,
        loop: true,
        items: 4,
        autoplayHoverPause: true,
        dots: true,
        navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
        nav: true, 
        responsive: {
          0: {
              items: 1,
          },
          400: {
              items: 1,
          },
          600: {
              items: 2,
          },
          700: {
              items: 2,
          },
          1000: {
              items: 3,
          },
          1200: {
              items: 4,
          },
          1400: {
              items: 4,
          },
          1600: {
              items: 4,
          },
      },
      };
    
    return (

      <section className="service-slider-section section-space-bottom p-relative">
        <div className="shape-1" style={{ backgroundImage: `url(${img1})` }}></div>
        <div className="shape-2 float-bob-y" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="custom-container">
          <div className="title-box text-center mb-60 wow fadeInLeft" data-wow-delay=".5s">
              <span className="section-sub-title">Best Services</span>
              <h3 className="section-title mt-10 mb-25">We Provide Best Quality <br/>Construction Service</h3>
              <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
          </div>
            <div className="services_slider">
              <ReactOwlCarousel className="service-active-1 owl-carousel" {...options}>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img3} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-saw"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Structural Engineering</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img4} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-concrete-mixer"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">House Renovation</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img5} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-house"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Material Supply</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img6} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-concrete-mixer-1"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Welding & Laser</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img3} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-saw"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Structural Engineering</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img4} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-concrete-mixer"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">House Renovation</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img5} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-house"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Material Supply</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img6} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-concrete-mixer-1"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Welding & Laser</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img3} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-saw"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Structural Engineering</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img4} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-concrete-mixer"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">House Renovation</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img5} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-house"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Material Supply</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
                <div className="slider_item"> 
                    <div className="service-slider-area p-relative">
                      <Link className="image w-img" to="/services">
                          <img src={img6} alt="" />
                      </Link>
                      <div className="content-area">
                          <div className="content text-center">
                            <div className="icon-box">
                                <i className="icon-concrete-mixer-1"></i>
                            </div>
                            <h5 className="mb-10"><Link to="/services">Welding & Laser</Link></h5>
                            <p>We leverage cutting-edgestae technologies to create.</p>
                          </div>
                      </div>
                      <Link className="btn-icon" to="/services">
                          <i className="icon-right-arrow"></i>
                      </Link>
                    </div>
                </div>
              </ReactOwlCarousel>
          </div>
        </div>
    </section>
    );
};

export default Service;