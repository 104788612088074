import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo/logo-white.svg";
import img1 from "../../assets/images/resources/group-1.png";
import img2 from "../../assets/images/resources/footer-1.jpg";
import img3 from "../../assets/images/resources/footer-2.jpg";
import img4 from "../../assets/images/resources/footer-3.jpg";
import img5 from "../../assets/images/resources/footer-4.jpg";
import img6 from "../../assets/images/resources/footer-5.jpg";
import img7 from "../../assets/images/resources/footer-6.jpg";
import img8 from "../../assets/images/bg/footer-bg.png";

const FooterOne = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
      };

    return (
        <>
            <footer>
                <div className="footer-main bg-color-1 p-relative">
                    <div className="shape" style={{ backgroundImage: `url(${img8})` }}></div>
                        <div className="custom-container p-relative">
                            <div className="footer-top pt-65 pb-30">
                                <div className="footer-logo">
                                <Link to="/">
                                    <img src={Logo} alt="" />
                                </Link>
                                </div>
                                <div className="footer-call">
                                    <div className="images">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="info">
                                        <span>Have Any Question ? Call</span>
                                        <h4><Link to="tel:4065550120">(406) 555-0120</Link></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-middle pt-50 pb-70">
                                <div className="row g-4">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                        <div className="footer-widget-1">
                                            <h5 className="footer-widget-title mb-20">About Company</h5>
                                            <p>We leverage cutting-edge technologies to create compelling content across various digital platforms.</p>
                                            <ul className="company-info mt-30">
                                                <li className="phone-number"><Link to="tel:12463330088">+ 1 (246) 333-0088</Link></li>
                                                <li className="email"><Link to="mailto:alma.lawson@example.com">alma.lawson@example.com</Link></li>
                                                <li className="address">4140 Parker Rd. Allentown, New Mexico 31134</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                        <div className="footer-widget-2 pl-70 pr-70">
                                            <h5 className="footer-widget-title mb-30">Quick Link</h5>
                                            <ul className="footer-menu-links">
                                                <li><Link to="services.html">Popular Services</Link></li>
                                                <li><Link to="blog-grid.html">Latest News</Link></li>
                                                <li><Link to="team.html">Team Member</Link></li>
                                                <li><Link to="testimonials.html">Testimonial</Link></li>
                                                <li><Link to="contact.html">Company History</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                        <div className="footer-widget-3">
                                            <h5 className="footer-widget-title mb-25">News Letter</h5>
                                            <p>We leverage cutting-edge technologies to create compelling .</p>
                                            <div className="subscribe-form p-relative">
                                                <form action="contact" method="post" className="subscribe-form" onSubmit={handleSubmit}>
                                                    <input type="email" placeholder="Email Address" required />
                                                    <button type="submit"><i className="icon-arrow_carrot-right"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                        <div className="footer-widget-4">
                                            <h5 className="footer-widget-title mb-15">Gallery</h5>
                                            <div className="footer-gallery">
                                                <div className="image-area p-relative">
                                                    <Link className="popup-image" href={img2}>
                                                    <img src={img2} alt="" />
                                                    <i className="icon-plus"></i>
                                                    </Link>
                                                </div>
                                                <div className="image-area p-relative">
                                                    <Link className="popup-image" href={img3}>
                                                    <img src={img3} alt="" />
                                                    <i className="icon-plus"></i>
                                                    </Link>
                                                </div>
                                                <div className="image-area p-relative">
                                                    <Link className="popup-image" href={img4} >
                                                        <img src={img4} alt="" />
                                                    <i className="icon-plus"></i>
                                                    </Link>
                                                </div>
                                                <div className="image-area p-relative">
                                                    <Link className="popup-image" href={img5}>
                                                    <img src={img5} alt="" />
                                                    <i className="icon-plus"></i>
                                                    </Link>
                                                </div>
                                                <div className="image-area p-relative">
                                                    <Link className="popup-image" href={img6}>
                                                    <img src={img6} alt="" />
                                                    <i className="icon-plus"></i>
                                                    </Link>
                                                </div>
                                                <div className="image-area p-relative">
                                                    <Link className="popup-image" href={img7}>
                                                    <img src={img7} alt="" />
                                                    <i className="icon-plus"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom bg-color-2 pt-15 pb-15 text-center">
                                <p className="copy-right m-0">© 2024 <Link to="/">Buildsphere</Link>, All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                    </footer>
        </>
    );
};

export default FooterOne;