import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";

import img1 from "../../assets/images/team/team-1-4.jpg";

const TeamDetails = () => {
      pageTitle('Team Single');

  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>

        <section className="team-details-page section-space">
            <div className="custom-container">
                <div className="team-member-info">
                    <div className="row">
                        <div className="col-xxl-5 mb-50">
                            <div className="team-member-info-image-area p-relative">
                                <figure className="w-img">
                                    <img src={img1} alt="" />
                                </figure>
                                <ul className="team-member-socials-icon">
                                    <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-7 pl-20">
                            <h5 className="">Kristin Watson</h5>
                            <h6 className="mb-25 mt-20 designation">Senior QA Engineer</h6>
                            <hr />
                            <p className="mt-20 mb-20">Mauris sapien neque, placerat ut dolor nec, egestas tincidunt felis. Sed in ornare quam, finibus dui aliquam justo duis eros quam, semper at libero sed, vehicula the  consequat arcu. In ornare, enim at egestas bibendum, ligula ante congue arcu, sed ornare sem nulla is nec magna. Morbi faucibus sed ante eu bibendum. lacud Phasellus posuere mauris risus,</p>
                            <div className="team-details-progress-bar p-relative mb-30">
                                <h6>Analysis</h6>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" data-wow-duration="1s" data-wow-delay=".3s" role="progressbar" data-width="95%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: "95%", visibility: "visible", animationDuration: "1s", animationDelay: "0.3s", animationName: "slideInLeft" }}></div>
                                </div>
                                <span className="progress-count">95%</span>
                            </div>
                            <div className="team-details-progress-bar p-relative mb-30">
                                <h6>Optimization</h6>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" data-wow-duration="1s" data-wow-delay=".3s" role="progressbar" data-width="85%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: "85%", visibility: "visible", animationDuration: "1s", animationDelay: "0.3s", animationName: "slideInLeft" }}></div>
                                </div>
                                <span className="progress-count">85%</span>
                            </div>
                            <div className="team-details-progress-bar p-relative">
                                <h6>Branding</h6>
                                <div className="progress">
                                    <div className="progress-bar wow slideInLeft" data-wow-duration="1s" data-wow-delay=".3s" role="progressbar" data-width="98%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: "98%", visibility: "visible", animationDuration: "1s", animationDelay: "0.3s", animationName: "slideInLeft" }}></div>
                                </div>
                                <span className="progress-count">98%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-member-info-details pt-30">
                    <h5 className="mb-30">Education Background</h5>
                    <hr />
                    <div className="edu-info mb-30 mt-30">
                        <h6>Diploma - Civil</h6>
                        <span>2012 - 2016</span>
                    </div>
                    <p className="mb-50">Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore of magna aliqua. Ut enim ad minim veniam, made of owl the is quis nostrud vitae exercitation ullamco laboris nisi ut aliquip ex ea dolor commodo consequat. Duis aute irure and dolor in reprehenderit The is ipsum dolor sit amet consectetur nulla adipiscing elit. Fusce eleifend porta arcu In hac habitasse the is platea augue thelorem turpoi dictumst. In lacus libero faucibus at malesuada sagittis placerat eros sed istincidunt augue ac ante rutrum sed the is sodales augue consequat..</p>

                    <div className="edu-info mb-30">
                        <h6>Bsc - Civil</h6>
                        <span>2016 - 2020 </span>
                    </div>
                    <p>Duis ac ligula vel enim vehicula semper. Praesent diam enim, ultricies sed pharetra in, vestibulum eget elit. Praesent condimentum, eros vitae feugiat faucibus, elit arcu commodo orci, et sodales orci lacus vel urna. Nullam sodales rhoncus nulla, quis gravida mauris blandit ut. Cras a massa non neque faucibus porta id et leo. Nunc sit amet volutpat libero. Maecenas aliquet blandit libero sit amet efficitur. Nam mauris nisi,</p>
                </div>
            </div>
        </section>
      
        <FooterTwo></FooterTwo>
    </>
  );
};

export default TeamDetails;