import React, { useState } from 'react';
import img1 from "../../../assets/images/shapes/shape-9.png";
import img2 from "../../../assets/images/shapes/shape-11.png";
import img3 from "../../../assets/images/resources/project-form-1.png";
import ModalVideo from 'react-modal-video'

const FormOne = () => {

  const [isOpen, setOpen] = useState(false)

  const SubmitHandler = (e) => {
    e.preventDefault()
}

  return (

    <section className="projects-form-section p-relative bg-color-1 fix">
      <div className="car-shape" style={{ backgroundImage: `url(${img1})` }}></div>
      <div className="round-yellow-shape" style={{ backgroundImage: `url(${img2})` }}></div>
      <div className="custom-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
                <div className="project-form-container pt-70 pb-70">
                  <div className="title-box mb-50 wow fadeInLeft" data-wow-delay=".5s">
                      <span className="section-sub-title">Get In touch</span>
                      <h3 className="section-title mt-10 mb-25">Talk About Your Next Dream Project</h3>
                  </div>
                  <div className="projects-form-area p-relative z-2 wow fadeInRight" data-wow-delay=".5s">
                      <form id="form" method="post" onSubmit={SubmitHandler}>
                        <div className="row">
                            <div className="col-lg-6">
                              <input id="name" name="name" type="text" placeholder="Full Name" />
                            </div>
                            <div className="col-lg-6">
                              <input type="tel" placeholder="Phone No." />
                            </div>
                            <div className="col-lg-6">
                              <input id="email" name="email" type="email" placeholder="Email Address*" required />
                            </div>
                            <div className="col-lg-6">
                              <input type="text" placeholder="Subject" />
                            </div>
                            <div className="col-lg-12">
                              <textarea id="message" name="message" placeholder="Message*" required></textarea>
                            </div>
                            <div className="col-lg-12">
                              <button type="submit" className="theme__button ">
                                  Send Message <i className="icon-arrow-double-right"></i>
                                  
                              </button>
                            </div>
                        </div>
                      </form>
                  </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 wow fadeInLeft" data-wow-delay=".5s">
                <div className="projects-form-image-area p-relative">
                  <div className="shape-bg w-img">
                      <img className="image-1" src={img3} alt="" />
                  </div>
                  <div className="play-btn">
                    <React.Fragment>
                      <ModalVideo
                          channel="youtube"
                          youtube={{ mute: 0, autoplay: 0 }}
                          isOpen={isOpen}
                          videoId="L61p2uyiMSo"
                          onClose={() => setOpen(false)} 
                      />
                      <button className="popup-video" onClick={() => setOpen(true)}>
                        <div className="video_player_btn">
                    
                          <i className="icon-arrow_triangle-right"></i>
                        </div>
                       </button>
                      </React.Fragment>
                  </div>  
                </div>
            </div>
          </div>
      </div>
    </section>

  
  );
};

export default FormOne;
