import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeOne from "./Pages/HomeOne/HomeOne/HomeOne";
import HomeTwo from "./Pages/HomeTwo/HomeTwo/HomeTwo";
import HomeThree from "./Pages/HomeThree/HomeThree/HomeThree";
import About from "./Pages/About/About";
import Team from "./Pages/Team/Team";
import TeamDetails from "./Pages/TeamDetails/TeamDetails";
import Testimonials from "./Pages/Testimonials/Testimonials";
import Faq from "./Pages/Faq/Faq";
import Error from "./Pages/Error/Error";
import Services from "./Pages/Services/Services";
import ServiceDetails from "./Pages/ServiceDetails/ServiceDetails";
import Projects from "./Pages/Projects/Projects";
import ProjectsDetails from "./Pages/ProjectsDetails/ProjectsDetails";
import Contact from "./Pages/Contact/Contact";
import BlogRightSidebar from "./Pages/BlogRightSidebar/BlogRightSidebar";
import BlogLeftSidebar from "./Pages/BlogLeftSidebar/BlogLeftSidebar";
import BlogFullwidth from "./Pages/BlogFullwidth/BlogFullwidth";
import BlogDetails from "./Pages/BlogDetails/BlogDetails";
import BlogSingleLeft from "./Pages/BlogSingleLeft/BlogSingleLeft";
import BlogSingleFullwidth from "./Pages/BlogSingleFullwidth/BlogSingleFullwidth";


function App() {
    
  return (
    <>
        <Router><Routes>
            <Route path="/" element={<HomeOne />} />
            <Route path="/home-two" element={<HomeTwo />} />
            <Route path="/home-three" element={<HomeThree />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/team-details" element={<TeamDetails />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/error" element={<Error />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service-details" element={<ServiceDetails />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects-details" element={<ProjectsDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
            <Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
            <Route path="/blog-fullwidth" element={<BlogFullwidth />} />
            <Route path="/blog-details" element={<BlogDetails />} />
            <Route path="/blog-details-left-sidebar" element={<BlogSingleLeft />} />
            <Route path="/blog-details-fullwidth" element={<BlogSingleFullwidth />} />
        </Routes></Router>

        
        

    </>
  )
}

export default App
