import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';

import img1 from "../../../assets/images/bg/case-bg.png";
import img2 from "../../../assets/images/shapes/shape-25.png";
import img3 from "../../../assets/images/resources/case-2.png";
import img4 from "../../../assets/images/resources/case-1.png";

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

const CaseStudy = () => {

    const swiperRef = useRef(null);

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();

        swiperRef.current = new Swiper('.banner-active', {
            slidesPerView: 1,
            slidesPerColumn: 1,
            paginationClickable: true,
            fadeEffect: {
                crossFade: true,
            },
            loop: true,
            effect: "fade",
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: ".slider-button-next",
                prevEl: ".slider-button-prev",
            },
            pagination: {
                el: ".banner-dot",
                clickable: true,
            },
            a11y: false,
        });

        // Clean up function
        return () => {
            swiperRef.current.destroy();
        };
      }, [])
  

  return (

    <section className="case-study-section p-relative pt-120" style={{ backgroundImage: `url(${img1})` }}>
        <div className="custom-container">
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="title-box mb-70 wow fadeInRight" data-wow-delay=".5s">
                        <span className="section-sub-title">CASE STUDY</span>
                        <h3 className="section-title mt-10 mb-25">Successfully completed projects for our clients</h3>
                        <p>We have facility to produce advance work various industrial applications based on specially developed technol-ogy.</p>
                    </div>
                    <div className="case-study-content">
                        <div className="case_navigation__wrapprer position-relative">
                        <div className="common-slider-navigation">
                            <button className="slider-button-prev"><i className="fal fa-arrow-left-long"></i></button>
                            <button className="slider-button-next"><i className="fal fa-arrow-right-long"></i></button>
                        </div>
                        </div>
                        <div className="swiper slider-active">
                            <div className="swiper-wrapper">
                                <div className="item swiper-slide">
                                    <div className="inner">
                                        <h3 className="mb-20"><Link to="/projects-details">Engineering Marvels</Link></h3>
                                        <p>Industrial gases are everywhere. They are an integral part of modern manufacturing processes and play an essential role in</p>
                                    </div>
                                </div>
                                <div className="item swiper-slide">
                                    <div className="inner">
                                        <h3 className="mb-20"><Link to="/projects-details">House Renovation</Link></h3>
                                        <p>Industrial gases are everywhere. They are an integral part of modern manufacturing processes and play an essential role in</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="case-shape w-img" style={{ maskImage: `url(${img2})`, WebkitMaskImage: `url(${img2})` }}>
                        <div className="swiper banner-active">
                            <div className="swiper-wrapper">
                                <div className="item swiper-slide">
                                    <img className="case-image" src={img3} alt="" />
                                </div>
                                <div className="item swiper-slide">
                                    <img className="case-image" src={img4} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    </section>
  
  );
};

export default CaseStudy;
