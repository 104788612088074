import React from 'react';
import FooterThree from '../../Footer/FooterThree';
import HeaderThree from '../../Header/HeaderThree';
import BannerThree from '../../HomeThree/BannerThree/BannerThree';
import FunfactThree from '../../HomeThree/FunfactThree/FunfactThree';
import TextAnimationThree from '../../HomeThree/TextAnimationThree/TextAnimationThree';
import AboutThree from '../../HomeThree/AboutThree/AboutThree';
import CaseStudy from '../../HomeThree/CaseStudy/CaseStudy';
import ServiceThree from '../../HomeThree/ServiceThree/ServiceThree';
import GetContact from '../../HomeTwo/GetContact/GetContact';
import GetContactThree from '../../HomeThree/GetContactThree/GetContactThree';
import TeamThree from '../../HomeThree/TeamThree/TeamThree';
import TestimonialTwo from '../../HomeTwo/TestimonialTwo/TestimonialTwo';
import Blog from '../../HomeOne/Blog/Blog';
import Brand from '../../HomeOne/Brand/Brand';

import { pageTitle } from '../../PageTitle';


const HomeThree = () => {

    pageTitle('Buildsphere');
    return (
        <>
          
            <HeaderThree></HeaderThree>
            <BannerThree></BannerThree>
            <FunfactThree></FunfactThree>
            <TextAnimationThree></TextAnimationThree>
            <AboutThree></AboutThree>
            <CaseStudy></CaseStudy>
            <ServiceThree></ServiceThree>
            <GetContact></GetContact>
            <GetContactThree></GetContactThree>
            <TeamThree></TeamThree>
            <TestimonialTwo></TestimonialTwo>
            <Blog></Blog>
            <Brand></Brand>

            <FooterThree></FooterThree>
          
        </>
    );
};

export default HomeThree;