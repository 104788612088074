import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import img1 from "../../../assets/images/bg/team-bg.png";
import img2 from "../../../assets/images/services/service-thumb.jpg";
import img3 from "../../../assets/images/services/service-thumb-2.jpg";
import img4 from "../../../assets/images/services/service-thumb-3.jpg";
import img5 from "../../../assets/images/services/service-thumb-4.jpg";

const ServiceThree = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
    
    return (
        <>
            <section className="service-3-section section-space-bottom" style={{ backgroundImage: `url(${img1})` }}>
                <div className="custom-container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="title-box mr-70 wow fadeInLeft" data-wow-delay=".5s">
                            <span className="section-sub-title">What We SErvices</span>
                            <h3 className="section-title mt-10 mb-25">Excellent Service & Support for you</h3>
                            <p>For residential, automotive and commercial locksmith services choose Locksmith West Los Angeles, we are knowledgeable and leading the industry in the local area for years.</p>
                            <p>We have wide experience on any job that may involve locks, keys and security.</p>
                            <Link className="theme__button  mt-30" to="/services">
                                View All Services <i className="icon-arrow-double-right"></i>
                                
                            </Link>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="service-3-main-content p-relative">
                            <div className="service-box">
                                <Link className="service-3-link" to="/service-details"></Link>
                                <div className="icon-box">
                                    <div className="icon-1">
                                        <i className="icon-circular-saw1"></i>
                                    </div>
                                    <h5><Link to="/service-details">Structural Engineering</Link></h5>
                                </div>
                                <div className="content">
                                    <p className="m-0">For residential, automotive and commercial locksmith services choose Locksmith.</p>
                                    <Link to="/service-details"><i className="fal fa-arrow-right-long"></i></Link>
                                </div>
                                <figure className="service-image">
                                    <img src={img2} alt="" />
                                </figure>
                            </div>
                            <div className="service-box">
                                <Link className="service-3-link" to="/service-details"></Link>
                                <div className="icon-box">
                                    <div className="icon-1">
                                        <i className="icon-tool"></i>
                                    </div>
                                    <h5><Link to="/service-details">House Renovation</Link></h5>
                                </div>
                                <div className="content">
                                    <p className="m-0">For residential, automotive and commercial locksmith services choose Locksmith.</p>
                                    <Link to="/service-details"><i className="fal fa-arrow-right-long"></i></Link>
                                </div>
                                <figure className="service-image">
                                    <img src={img3} alt="" />
                                </figure>
                            </div>
                            <div className="service-box">
                                <Link className="service-3-link" to="/service-details"></Link>
                                <div className="icon-box">
                                    <div className="icon-1">
                                        <i className="icon-tool-box"></i>
                                    </div>
                                    <h5><Link to="/service-details">Material  Supply</Link></h5>
                                </div>
                                <div className="content">
                                    <p className="m-0">For residential, automotive and commercial locksmith services choose Locksmith.</p>
                                    <Link to="/service-details"><i className="fal fa-arrow-right-long"></i></Link>
                                </div>
                                <figure className="service-image">
                                    <img src={img4} alt="" />
                                </figure>
                            </div>
                            <div className="service-box">
                                <Link className="service-3-link" to="/service-details"></Link>
                                <div className="icon-box">
                                    <div className="icon-1">
                                        <i className="icon-construction"></i>
                                    </div>
                                    <h5><Link to="/service-details">Welding & Laser</Link></h5>
                                </div>
                                <div className="content">
                                    <p className="m-0">For residential, automotive and commercial locksmith services choose Locksmith.</p>
                                    <Link to="/service-details"><i className="fal fa-arrow-right-long"></i></Link>
                                </div>
                                <figure className="service-image">
                                    <img src={img5} alt="" />
                                </figure>
                            </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceThree;