import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/team/team-1.jpg";
import img2 from "../../../assets/images/team/team-2.jpg";
import img3 from "../../../assets/images/team/team-3.jpg";
import WOW from 'wowjs';

const TeamOne = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (

    <section className="team-section p-relative z-1">
        <div className="custom-container">
        <div className="title-box text-center mb-60 wow fadeInLeft" data-wow-delay=".5s">
            <span className="section-sub-title">Professional Expert</span>
            <h3 className="section-title mt-10 mb-25">Meet The Team</h3>
        </div>
        <div className="row g-4">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-15 wow fadeInRight" data-wow-delay=".5s">
                <div className="team-area-box p-relative">
                    <figure className="image w-img">
                    <img src={img1} alt="" />
                    </figure>
                    <div className="content">
                    <div>
                        <h5><Link to="team-details.html">Esther Howard</Link></h5>
                        <span>Consultant</span>
                    </div>
                    <div className="socials-media-area p-relative">
                        <i className="icon-share"></i>
                        <ul>
                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-15 wow fadeInDown" data-wow-delay=".5s">
                <div className="team-area-box p-relative">
                    <figure className="image w-img">
                    <img src={img2} alt="" />
                    </figure>
                    <div className="content">
                    <div>
                        <h5><Link to="team-details.html">Jenny Wilson</Link></h5>
                        <span>Consultant</span>
                    </div>
                    <div className="socials-media-area p-relative">
                        <i className="icon-share"></i>
                        <ul>
                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-15 wow fadeInLeft" data-wow-delay=".5s">
                <div className="team-area-box p-relative">
                    <figure className="image w-img">
                    <img src={img3} alt="" />
                    </figure>
                    <div className="content">
                    <div>
                        <h5><Link to="team-details.html">Kaka Hawkins</Link></h5>
                        <span>Consultant</span>
                    </div>
                    <div className="socials-media-area p-relative">
                        <i className="icon-share"></i>
                        <ul>
                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>

  );
};

export default TeamOne;
