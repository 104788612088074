import React from 'react';
import FooterTwo from '../../Footer/FooterTwo';
import HeaderTwo from '../../Header/HeaderTwo';
import TextAnimation from '../../HomeTwo/TextAnimation/TextAnimation';
import BannerTwo from '../../HomeTwo/BannerTwo/BannerTwo';
import ServicesTwo from '../../HomeTwo/ServicesTwo/ServicesTwo';
import AboutTwo from '../../HomeTwo/AboutTwo/AboutTwo';
import TextAnimationTwo from '../../HomeTwo/TextAnimationTwo/TextAnimationTwo';
import TextAnimationThree from '../../HomeTwo/TextAnimationThree/TextAnimationThree';
import FunfactTwo from '../../HomeTwo/FunfactTwo/FunfactTwo';
import ProjectsTwo from '../../HomeTwo/ProjectsTwo/ProjectsTwo';
import TeamTwo from '../../HomeTwo/TeamTwo/TeamTwo';
import GetContact from '../../HomeTwo/GetContact/GetContact';
import GoogleMap from '../../HomeTwo/GoogleMap/GoogleMap';
import TestimonialTwo from '../../HomeTwo/TestimonialTwo/TestimonialTwo';
import BrandTwo from '../../HomeTwo/BrandTwo/BrandTwo';
import Blog from '../../HomeOne/Blog/Blog';

import { pageTitle } from '../../PageTitle';


const HomeTwo = () => {

    pageTitle('Buildsphere');
    return (
        <>
          <div className="bar-1"></div>
          <div className="bar-2"></div>
          <div className="bar-3"></div>
          <div className="bar-4"></div>
            
            <HeaderTwo></HeaderTwo>
            <TextAnimation></TextAnimation>
            <BannerTwo></BannerTwo>
            <ServicesTwo></ServicesTwo>
            <AboutTwo></AboutTwo>
            <TextAnimationTwo></TextAnimationTwo>
            <TextAnimationThree></TextAnimationThree>
            <FunfactTwo></FunfactTwo>
            <ProjectsTwo></ProjectsTwo>
            <TeamTwo></TeamTwo>
            <GetContact></GetContact>
            <GoogleMap></GoogleMap>
            <TestimonialTwo></TestimonialTwo>
            <BrandTwo></BrandTwo>
            <Blog></Blog>
          
            <FooterTwo></FooterTwo>
        </>
    );
};

export default HomeTwo;