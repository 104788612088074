import React from 'react';

const TextAnimationTwo = () => {

  return (

    <section className="text-slider-section fix">
        <div className="container-fluid">
        <div className="text-slider-box-1">
                <div className="slide-box one">
                    <h1>*Hand wood</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Out door</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Hand wood</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Out door</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Hand wood</h1>
                </div>
                <div className="slide-box one">
                    <h1>*Out door</h1>
                </div>
        </div>
        </div>
    </section>


  
  );
};

export default TextAnimationTwo;
