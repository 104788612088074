import React from 'react';

const TextAnimationThree = () => {

  return (

    <section className="text-slider-section fix">
        <div className="container-fluid">
        <div className="text-slider-box-2">
                <div className="slide-box two">
                    <h1>*House</h1>
                </div>
                <div className="slide-box two">
                    <h1>*Office</h1>
                </div>
                <div className="slide-box two">
                    <h1>*Windows</h1>
                </div>
                <div className="slide-box two">
                    <h1>*House</h1>
                </div>
                <div className="slide-box two">
                    <h1>*Office</h1>
                </div>
                <div className="slide-box two">
                    <h1>*Windows</h1>
                </div>
        </div>
        </div>
    </section>


  
  );
};

export default TextAnimationThree;
