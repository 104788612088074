import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from "../../../assets/images/brand/brand-1.png";
import img2 from "../../../assets/images/brand/brand-2.png";
import img3 from "../../../assets/images/brand/brand-3.png";
import img4 from "../../../assets/images/brand/brand-4.png";

const Brand = () => {
    const options = {
        smartSpeed: 2000,
        margin: 50,
        loop: true,
        items: 2,
        autoplayHoverPause: true,
        dots: true,
        navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
        nav: true, 
        responsive: {
          0: {
              items: 1,
          },
          400: {
              items: 2,
          },
          600: {
              items: 2,
          },
          700: {
              items: 2,
          },
          1000: {
              items: 3,
          },
          1200: {
              items: 4,
          },
        },
    };

    return (
        <section className="brand-section section-space-bottom">
            <div className="custom-container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="title-box wow fadeInLeft" data-wow-delay=".5s">
                            <span className="section-sub-title">Our Partners</span>
                            <h3 className="section-title mt-10 mb-25">We’ve 1400+ Global Premium Clients</h3>
                            <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 my-auto">
                        <div className="brand-slider-area">
                            <div className="brand-1 mb-30 text-center">
                                <OwlCarousel className="brand-active owl-carousel" {...options}>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img1} alt="" /></Link></span>
                                    </div>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img2} alt="" /></Link></span>
                                    </div>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img3} alt="" /></Link></span>
                                    </div>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img4} alt="" /></Link></span>
                                    </div>
                                </OwlCarousel>
                            </div>
                            <div className="brand-1 text-center">
                                <OwlCarousel className="brand-active owl-carousel" {...options}>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img1} alt="" /></Link></span>
                                    </div>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img2} alt="" /></Link></span>
                                    </div>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img3} alt="" /></Link></span>
                                    </div>
                                    <div className="slider_item"> 
                                        <span><Link to="#"><img src={img4} alt="" /></Link></span>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Brand;
