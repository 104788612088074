import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import img1 from "../../../assets/images/shapes/shape-6.png";
import img2 from "../../../assets/images/shapes/shape-7.png";
import img3 from "../../../assets/images/resources/features-2.jpg";
import img4 from "../../../assets/images/bg/bg-1.png";

const FeaturesOne = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
  

  return (

    <section className="features-section bg-color-1 p-relative fix">
        <div className="shape-2" style={{ backgroundImage: `url(${img1})` }}></div>
        <div className="shape-3" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="white-shape"></div>
        <div className="custom-container">
            <div className="row g-0">
                <div className="col-xxl-6 col-xl-6 col-lg-6 wow fadeInRight" data-wow-delay=".5s">
                    <div className="features-image-area p-relative" data-wow-delay="700ms">
                        <figure className="image w-img">
                            <img src={img3} alt="" />
                        </figure>
                        <figure className="image-shape w-img">
                            <img src={img4} alt="" />
                        </figure>
                    </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <div className="features-content p-relative">
                    <div className="title-box mb-30 wow fadeInLeft" data-wow-delay=".5s">
                        <span className="section-sub-title">our core features</span>
                        <h3 className="section-title mt-10 mb-25">We're Building Better Projects</h3>
                        <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="icon-box">
                                <div className="icon-1">
                                <i className="icon-crain"></i>
                                </div>
                                <div className="content p-relative z-1">
                                <h5><Link to="/services">Science of Building</Link></h5>
                                <span>We leverage cutting-edge.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="icon-box">
                                <div className="icon-1">
                                <i className="icon-mixer-truck"></i>
                                </div>
                                <div className="content p-relative z-1">
                                <h5><Link to="/services">Engineering Marvels</Link></h5>
                                <span>Cutting edge technologies </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
  
  );
};

export default FeaturesOne;
