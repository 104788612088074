import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/images/bg/map-bg.png";
import img2 from "../../../assets/images/flag/sweden.png";
import img3 from "../../../assets/images/flag/canada.png";
import img4 from "../../../assets/images/flag/usa.png";
import img5 from "../../../assets/images/flag/uk.png";
import img6 from "../../../assets/images/flag/thailand.png";
import WOW from 'wowjs';

const GoogleMap = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

  return (

    <section className="find-location-section p-relative section-space fix">
        <div className="custom-container">
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="title-box pr-100 mb-40 wow fadeInLeft" data-wow-delay=".5s" >
                    <span className="section-sub-title">Find Location</span>
                    <h3 className="section-title mt-10 mb-25">We are Helping Always Customer Globally.</h3>
                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. </p>
                </div>
                <Link className="theme__button " to="/contact">
                    Contact Us <i className="icon-arrow-double-right"></i>
                    
                </Link>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="find-location-map-area p-relative">
                    <figure className="w-img">
                        <img src={img1} alt="" />
                    </figure>
                    <div className="flag-1">
                        <div className="inner p-relative">
                            <img className=" wow pulse" data-wow-delay=".5s" src={img6} alt="" />
                            <div className="content">
                            <h6>Sweden Branch</h6>
                            <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
                            </div>
                        </div>
                    </div>
                    <div className="flag-2">
                        <div className="inner p-relative">
                            <img className=" wow pulse" data-wow-delay=".5s" src={img2} alt="" />
                            <div className="content">
                            <h6>Canada Branch</h6>
                            <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
                            </div>
                        </div>
                    </div>
                    <div className="flag-3">
                        <div className="inner p-relative">
                            <img className=" wow pulse" data-wow-delay=".5s" src={img3} alt="" />
                            <div className="content">
                            <h6>USA Branch</h6>
                            <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
                            </div>
                        </div>
                    </div>
                    <div className="flag-4">
                        <div className="inner p-relative">
                            <img className=" wow pulse" data-wow-delay=".5s" src={img4} alt="" />
                            <div className="content">
                            <h6>UK Branch</h6>
                            <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
                            </div>
                        </div>
                    </div>
                    <div className="flag-5">
                        <div className="inner p-relative">
                            <img className=" wow pulse" data-wow-delay=".5s" src={img5} alt="" />
                            <div className="content">
                            <h6>Thailand Branch</h6>
                            <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>

  );
};

export default GoogleMap;
