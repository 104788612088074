import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";

import img1 from "../../assets/images/blogs/blog-1-1.jpg";
import img2 from "../../assets/images/blogs/blog-1-2.jpg";
import img3 from "../../assets/images/blogs/blog-4.jpg";
import img4 from "../../assets/images/blogs/blog-5.jpg";
import img5 from "../../assets/images/blogs/blog-6.jpg";

const BlogRightSidebar = () => {
      pageTitle('Blog Right Sidebar');

      const SubmitHandler = (e) => {
        e.preventDefault()
    }


  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>
       
        <section className="postbox__area grey-bg-4 section-space">
            <div className="container">
                <div className="row gy-50">
                    <div className="col-xxl-8 col-lg-8">
                        <div className="postbox__wrapper">
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img1} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <i className="far fa-user"></i>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <i className="far fa-calendar"></i>
                                        <Link to="#">January 02, 2024</Link>
                                    </span>
                                    <span>
                                    <i className="far fa-comment"></i>
                                    <Link to="#">5</Link>
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                                <div className="postbox__read-more">
                                    <Link className="theme__button " to="/blog-details">
                                    Explore Now !
                                    <span ></span>
                                    </Link>
                                </div>
                            </div>
                        </article>
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img2} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <i className="far fa-user"></i>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <i className="far fa-calendar"></i>
                                        <Link to="#">January 02, 2024</Link>
                                    </span>
                                    <span>
                                    <i className="far fa-comment"></i>
                                    <Link to="#">5</Link>
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                                <div className="postbox__read-more">
                                    <Link className="theme__button " to="/blog-details">
                                    Explore Now !
                                    </Link>
                                </div>
                            </div>
                        </article>
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img1} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <i className="far fa-user"></i>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <i className="far fa-calendar"></i>
                                        <Link to="#">January 02, 2024</Link>
                                    </span>
                                    <span>
                                    <i className="far fa-comment"></i>
                                    <Link to="#">5</Link>
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                                <div className="postbox__read-more">
                                    <Link className="theme__button " to="/blog-details">
                                    Explore Now !
                                    
                                    </Link>
                                </div>
                            </div>
                        </article>
                        <div className="pagination__wrapper">
                            <div className="bd-basic__pagination d-flex align-items-center justify-content-center">
                                <nav>
                                    <ul>
                                    <li>
                                        <Link to="#">1</Link>
                                    </li>
                                    <li>
                                        <Link to="#">2</Link>
                                    </li>
                                    <li>
                                        <span className="current">3</span>
                                    </li>
                                    <li>
                                        <Link to="#"><i className="fa-regular fa-angle-right"></i></Link>
                                    </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-lg-4">
                        <div className="sidebar__wrapper pl-30">
                        <div className="sidebar__widget mb-20">
                            <div className="sidebar__widget-content">
                                <div className="sidebar__search">
                                    <form method="post" onSubmit={SubmitHandler}>
                                    <div className="sidebar__search-input">
                                        <input type="text" placeholder="Enter your keywords..." />
                                        <button type="submit">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M19.0002 19.0002L17.2002 17.2002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar__widget mb-45">
                            <h3 className="sidebar__widget-title">Category</h3>
                            <div className="sidebar__widget-content">
                                <ul>
                                    <li><Link to="blog-grid.html">Engineering <span>10</span></Link></li>
                                    <li><Link to="blog-grid.html">House <span>08</span></Link></li>
                                    <li><Link to="blog-grid.html">Material <span>24</span></Link></li>
                                    <li><Link to="blog-grid.html">Welding <span>37</span></Link></li>
                                    <li><Link to="blog-grid.html">Services <span>103</span></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar__widget mb-45">
                            <h3 className="sidebar__widget-title">Recent Post</h3>
                            <div className="sidebar__widget-content">
                                <div className="sidebar__post">
                                    <div className="rc__post d-flex align-items-center">
                                    <div className="rc__post-thumb">
                                        <Link to="/blog-details"><img src={img3} alt="" /></Link>
                                    </div>
                                    <div className="rc__post-content">
                                        <h4 className="rc__post-title">
                                            <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                        </h4>
                                        <div className="rc__meta">
                                            <span>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>Jan 21, 2024
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="rc__post d-flex align-items-center">
                                    <div className="rc__post-thumb">
                                        <Link to="/blog-details"><img src={img4} alt="" /></Link>
                                    </div>
                                    <div className="rc__post-content">
                                        <h4 className="rc__post-title">
                                            <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                        </h4>
                                        <div className="rc__meta">
                                            <span>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>Jan 21, 2024
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="rc__post d-flex align-items-center">
                                    <div className="rc__post-thumb">
                                        <Link to="/blog-details"><img src={img5} alt="" /></Link>
                                    </div>
                                    <div className="rc__post-content">
                                        <h4 className="rc__post-title">
                                            <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                        </h4>
                                        <div className="rc__meta">
                                            <span>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.5 3.59961V7.49961L10.1 8.79961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>Jan 21, 2024
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar__widget">
                            <h3 className="sidebar__widget-title">Tags</h3>
                            <div className="sidebar__widget-content">
                                <div className="tagcloud">
                                    <Link to="#">Building</Link>
                                    <Link to="#">Construction</Link>
                                    <Link to="#">Design</Link>
                                    <Link to="#">Business</Link>
                                    <Link to="#">Home</Link>
                                    <Link to="#">Trend 2024</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
      
        <FooterTwo></FooterTwo>
    </>
  );
};

export default BlogRightSidebar;