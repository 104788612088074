import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";


const Contact = () => {
      pageTitle('Contact');

      const SubmitHandler = (e) => {
        e.preventDefault()
    }
    


  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>
       
        <section className="contact-info-section section-space">
            <div className="container">
                <div className="row g-4">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <div className="contact-info-box">
                        <div className="icon">
                            <i className="far fa-location-dot"></i>
                        </div>
                        <h5 className="mt-15 mb-15">Our Location</h5>
                        <Link to="#">House #15, Street Number #100, Mongla- 70000-000, UK.</Link>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <div className="contact-info-box">
                        <div className="icon">
                            <i className="far fa-envelope"></i>
                        </div>
                        <h5 className="mt-15 mb-15">Our Email Address</h5>
                        <Link to="mailto:support@buildsphere.com">support@buildsphere.com</Link>
                        <Link to="mailto:contact@buildsphere.com">contact@buildsphere.com</Link>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <div className="contact-info-box">
                        <div className="icon">
                            <i className="far fa-phone-alt"></i>
                        </div>
                        <h5 className="mt-15 mb-15">Contact Number</h5>
                        <Link to="tel:001122334455">00-11-22334455</Link>
                        <Link to="tel:001122334456">00-11-22334456</Link>
                        </div>
                    </div>
                    <div className="contact-wrapper pt-80">
                        <div className="row gy-50">
                        <div className="col-xxl-6 col-xl-6">
                            <div className="contact-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4216.433331900906!2d90.36996032419312!3d23.83718617432321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1693682874850!5m2!1sen!2sbd"
                                width="600"
                                height="450"
                                title="Google Maps"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                ></iframe>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6">
                            <div className="contact-from">
                                <form id="form" method="post" onSubmit={SubmitHandler}>
                                    <div className="row">
                                    <div className="col-lg-6">
                                        <div className="contact__from-input">
                                            <input id="name" name="name" type="text" placeholder="Full Name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact__from-input">
                                            <input id="email" name="email" type="text" placeholder="Email Address*" required="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact__from-input">
                                            <input id="phone" name="phone" type="tel" placeholder="Phone Number" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact__from-input">
                                            <input type="text" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="contact__from-input">
                                            <textarea id="message" name="message" placeholder="Your Message*" required=""></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="contact__btn">
                                            <button className="theme__button contact_btn" type="submit" id="button">
                                                Send Now
                                                
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
      
        <FooterTwo></FooterTwo>
    </>
  );
};

export default Contact;