import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import news1 from "../../../assets/images/blogs/blog-1.jpg";
import news2 from "../../../assets/images/blogs/blog-2.jpg";
import news3 from "../../../assets/images/blogs/blog-3.jpg";
import WOW from 'wowjs';

const Blog = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

    const blogData = [
      {
        id: 1,
        title: 'We are Best any Industrial & Business Solution',
        date: '10',
        month: 'Dec 2024',
        author: ' John Duo',
        image: news1,
        href: '/blog-details',
      },
      {
        id: 2,
        title: 'How to Build for Best New Machinery Factory',
        date: '14',
        month: 'Jan 2024',
        author: ' John Duo',
        image: news2,
        href: '/blog-details',
      },
      {
        id: 3,
        title: 'How to Boost Productivity in the Factory Process',
        date: '15',
        month: 'Feb 2024',
        author: ' John Duo',
        image: news3,
        href: '/blog-details',
      },
      
    ];
    return (

        <section className="blog-section section-space">
            <div className="custom-container">
                <div className="title-box text-center mb-60 wow fadeInLeft" data-wow-delay=".5s">
                    <span className="section-sub-title">Latest News & Blogs</span>
                    <h3 className="section-title mt-10 mb-25">Read Our Constriction News <br/>Blogs & Articles</h3>
                    <p>We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
                </div>
                <div className="row g-4 wow fadeInRight" data-wow-delay=".5s">
                    {blogData.map((item, id)=>(
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-15" key={id}>
                            <div className="blog-style-one p-relative">
                                <Link className="image w-img" to={item.href}>
                                    <img src={item.image} alt="" />
                                </Link>
                                <div className="blog-content-area p-relative">
                                <div className="date-meta">
                                    <Link className="date" href="#">{item.date}</Link>
                                    <Link className="month" href="#">{item.month}</Link>
                                </div>
                                <div className="post-meta">
                                    <ul>
                                        <li><i className="icon-user-2"></i> <Link href="#">{item.author}</Link></li>
                                        <li><i className="icon-chat-bubble-1"></i> <Link href="#">0 comments</Link></li>
                                    </ul>
                                </div>
                                <h5 className="blog-title"><Link to={item.href}>{item.title}</Link></h5>
                                <Link className="blog-btn-box" to={item.href}>
                                    <span>Read More</span>
                                    <span><i className="icon-round-arrow-right"></i></span>
                                </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default Blog;