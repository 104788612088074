import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo/logo-white.svg";
import img1 from "../../assets/images/bg/footer-bg.png";
import img2 from "../../assets/images/shapes/shape-24.png";

const FooterTwo = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
      };

    return (
        <>

            <footer>
                <div className="footer-main footer-style-2 bg-color-1 p-relative pt-120 fix">
                    <div className="shape" style={{ backgroundImage: `url(${img1})` }}></div>
                    <div className="car-shape" style={{ backgroundImage: `url(${img2})` }}></div>
                    <div className="custom-container p-relative">
                        <div className="footer-middle pb-70">
                            <div className="row g-4">
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                    <div className="footer-widget-1 pr-15">
                                        <div className="footer-logo mb-30">
                                            <Link to="/">
                                                <img src={Logo} alt="" />
                                            </Link>
                                        </div>
                                        <p>We leverage cutting-edge technologies to create compelling content across various digital platforms.</p>
                                        <ul className="company-info mt-30 p-relative z-2">
                                            <li className="phone-number"><Link to="tel:12463330088">+ 1 (246) 333-0088</Link></li>
                                            <li className="email"><Link to="mailto:alma.lawson@example.com">alma.lawson@example.com</Link></li>
                                            <li className="address">4140 Parker Rd. Allentown, New Mexico 31134</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-2 col-lg-2 col-md-6">
                                    <div className="footer-widget-2 pl-70">
                                        <h5 className="footer-widget-title mb-30">Quick Links</h5>
                                        <ul className="footer-menu-links p-relative z-2">
                                            <li><Link to="/services">Popular Services</Link></li>
                                            <li><Link to="/blog-grid">Latest News</Link></li>
                                            <li><Link to="/team">Team Member</Link></li>
                                            <li><Link to="/testimonials">Testimonial</Link></li>
                                            <li><Link to="/contact">Company History</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                    <div className="footer-widget-2">
                                        <h5 className="footer-widget-title mb-30">Services</h5>
                                        <ul className="footer-menu-links p-relative z-2">
                                            <li><Link to="/services">House Renovation</Link></li>
                                            <li><Link to="/services">Structural Engineering</Link></li>
                                            <li><Link to="/services">Material  Supply</Link></li>
                                            <li><Link to="/services">Welding & Laser</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6">
                                    <div className="footer-widget-3 two">
                                        <h5 className="footer-widget-title mb-25">Let's Get Started</h5>
                                        <p>We leverage cutting-edge technologies to create compelling .</p>
                                        <div className="subscribe-form p-relative z-2">
                                            <form action="contact" method="post" onSubmit={handleSubmit}>
                                                <input type="email" placeholder="Email Address" required="" />
                                                <button type="submit"><i className="icon-arrow_carrot-right"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom bg-color-2 pt-15 pb-15 text-center">
                            <p className="copy-right m-0 p-relative z-2">© 2024 <Link to="/">Buildsphere</Link>, All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterTwo; 