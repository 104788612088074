import React from 'react';

const TextAnimationThree = () => {

  return (
    <>
        <section className="text-slider-section">
            <div className="container-fluid">
                <div className="text-slider-box three">
                    <div className="slide-box three">
                        <h1>Since 1980</h1>
                    </div>
                    <div className="slide-box three">
                        <h1>Since 1980</h1>
                    </div>
                    <div className="slide-box three">
                        <h1>Since 1980</h1>
                    </div>
                    <div className="slide-box three">
                        <h1>Since 1980</h1>
                    </div>
                    <div className="slide-box three">
                        <h1>Since 1980</h1>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default TextAnimationThree;
