import React, {useEffect} from 'react';
import CountUp from 'react-countup';
import img1 from "../../../assets/images/bg/service-bg-1.png";
import img2 from "../../../assets/images/bg/service-bg-2.png";
import img3 from "../../../assets/images/shapes/shape-17.png";
import img4 from "../../../assets/images/resources/service-provider-2.jpg";
import img5 from "../../../assets/images/shapes/shape-18.png";
import img6 from "../../../assets/images/resources/service-provider-3.jpg";
import WOW from 'wowjs';

const FunfactTwo = () => {
    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])
  

  return (

    <section className="service-provider-2-section p-relative fix section-space bg-color-1">
        <div className="bg-1" style={{ backgroundImage: `url(${img1})` }}></div>
        <div className="bg-2" style={{ backgroundImage: `url(${img2})` }}></div>
            <div className="custom-container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="service-provider-content p-relative">
                            <div className="title-box mb-30 wow fadeInLeft" data-wow-delay=".5s">
                                <span className="section-sub-title">amazing industry</span>
                                <h3 className="section-title mt-10 mb-25">Best Industrial Services Provider</h3>
                                <p className="section-text">We leverage cutting-edge technologies to create compelling content <br/>across various digital platforms.</p>
                            </div>
                            <div className="service-provider-progress-bar mb-60">
                                <div className="progress-bar-1 p-relative">
                                    <h6 className="mb-10">Quality Services</h6>
                                    <div className="progress">
                                        <div className="progress-bar wow slideInLeft" style={{ width: "95%" }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <span className="progress-count">95%</span>
                                </div>
                                <div className="progress-bar-1 p-relative">
                                    <h6 className="mb-10">Dedicated Team</h6>
                                    <div className="progress">
                                        <div className="progress-bar wow slideInLeft" style={{ width: "99%" }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <span className="progress-count">99%</span>
                                </div>
                            </div>
                            <div className="counter-area">
                                <div className="counter-box">
                                    <div className="icon-box">
                                    <i className="icon-left-angle"></i>
                                    </div>
                                    <h2><CountUp className="counter" start={0} end={203} />+</h2>
                                    <h5>Project Complete</h5>
                                </div>
                                <div className="counter-box">
                                    <div className="icon-box">
                                    <i className="icon-left-angle"></i>
                                    </div>
                                    <h2><CountUp className="counter" start={0} end={24} />+</h2>
                                    <h5>Quality Team</h5>
                                </div>
                                <div className="counter-box">
                                    <div className="icon-box">
                                    <i className="icon-left-angle"></i>
                                    </div>
                                    <h2><CountUp className="counter" start={0} end={30} />+</h2>
                                    <h5>Years Of Experience</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="service-provider-2-image-box">
                            <div className="image-1 wow slideInRight" data-wow-delay="500ms" style={{ maskImage: `url(${img3})`, WebkitMaskImage: `url(${img3})` }}>
                                <img src={img4} alt="" />
                            </div>
                            <div className="image-2 wow slideInRight" data-wow-delay="700ms" style={{ maskImage: `url(${img5})`, WebkitMaskImage: `url(${img5})` }}>
                                <img src={img6} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  
  );
};

export default FunfactTwo;
