import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';
import { Link } from "react-router-dom";

import img1 from "../../assets/images/blogs/blog-1-1.jpg";
import img2 from "../../assets/images/blogs/blog-1-2.jpg";

const BlogFullwidth = () => {
      pageTitle('Blog Full Width');
     

  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>
       
        <section className="postbox__area grey-bg-4 section-space">
            <div className="container">
                <div className="row gy-50">
                    <div className="col-xxl-12 col-lg-12">
                        <div className="postbox__wrapper">
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img1} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <i className="far fa-user"></i>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <i className="far fa-calendar"></i>
                                        <Link to="#">January 02, 2024</Link>
                                    </span>
                                    <span>
                                    <i className="far fa-comment"></i>
                                    <Link to="#">5</Link>
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                                <div className="postbox__read-more">
                                    <Link className="theme__button " to="/blog-details">
                                    Explore Now !
                                    <span ></span>
                                    </Link>
                                </div>
                            </div>
                        </article>
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img2} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <i className="far fa-user"></i>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <i className="far fa-calendar"></i>
                                        <Link to="#">January 02, 2024</Link>
                                    </span>
                                    <span>
                                    <i className="far fa-comment"></i>
                                    <Link to="#">5</Link>
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                                <div className="postbox__read-more">
                                    <Link className="theme__button " to="/blog-details">
                                    Explore Now !
                                    
                                    </Link>
                                </div>
                            </div>
                        </article>
                        <article className="postbox__item mb-50 transition-3">
                            <div className="postbox__thumb w-img mb-30">
                                <Link to="/blog-details">
                                    <img src={img1} alt="" />
                                </Link>
                            </div>
                            <div className="postbox__content">
                                <div className="postbox__meta">
                                    <span>
                                    <Link to="#">
                                        <i className="far fa-user"></i>
                                        By Admin
                                    </Link>
                                    </span>
                                    <span>
                                    <i className="far fa-calendar"></i>
                                        <Link to="#">January 02, 2024</Link>
                                    </span>
                                    <span>
                                    <i className="far fa-comment"></i>
                                    <Link to="#">5</Link>
                                    </span>
                                </div>
                                <h3 className="postbox__title">
                                    <Link to="/blog-details">We are Best any Industrial & Business Solution</Link>
                                </h3>
                                <div className="postbox__text">
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
                                    hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur,
                                    ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
                                    auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at
                                    maximus ante fermentum.</p>
                                </div>
                                <div className="postbox__read-more">
                                    <Link className="theme__button " to="/blog-details">
                                    Explore Now !
                                        
                                    </Link>
                                </div>
                            </div>
                        </article>
                        <div className="pagination__wrapper">
                            <div className="bd-basic__pagination d-flex align-items-center justify-content-center">
                                <nav>
                                    <ul>
                                    <li>
                                        <Link to="#">1</Link>
                                    </li>
                                    <li>
                                        <Link to="#">2</Link>
                                    </li>
                                    <li>
                                        <span className="current">3</span>
                                    </li>
                                    <li>
                                        <Link to="#"><i className="fa-regular fa-angle-right"></i></Link>
                                    </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
      
        <FooterTwo></FooterTwo>
    </>
  );
};

export default BlogFullwidth;