import React from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterTwo';

import img1 from "../../assets/images/resources/testimonial-2.png";
import img2 from "../../assets/images/resources/testimonial-1.png";

const Testimonials = () => {

      pageTitle('Testimonials');

  return (
    
    <>
        <HeaderTwo></HeaderTwo>
        <BreadCrumb></BreadCrumb>

        <section class="section-space">
            <div class="custom-container">
                <div class="row g-4">
                    <div class="col-lg-6 mb-15">
                        <div class="testimonials-box-area">
                        <div class="inner p-relative">
                            <div class="upper-area mb-20">
                                <div class="author-image">
                                    <img src={img1} alt="" />
                                </div>
                                <div class="icon-1">
                                    <i class="icon-quote-2"></i>
                                </div>
                                <div class="author-info">
                                    <h5>Robin Hood</h5>
                                    <span>Web design</span>
                                    <ul class="testimonials-ratings">
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    </ul> 
                                </div>
                            </div>
                            <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-15">
                        <div class="testimonials-box-area">
                        <div class="inner p-relative">
                            <div class="upper-area mb-20">
                                <div class="author-image">
                                    <img src={img2} alt="" />
                                </div>
                                <div class="icon-1">
                                    <i class="icon-quote-2"></i>
                                </div>
                                <div class="author-info">
                                    <h5>Darlene Robertson</h5>
                                    <span>Web design</span>
                                    <ul class="testimonials-ratings">
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    </ul>  
                                </div>
                            </div>
                            <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-15">
                        <div class="testimonials-box-area">
                        <div class="inner p-relative">
                            <div class="upper-area mb-20">
                                <div class="author-image">
                                    <img src={img1} alt="" />
                                </div>
                                <div class="icon-1">
                                    <i class="icon-quote-2"></i>
                                </div>
                                <div class="author-info">
                                    <h5>Robin Hood</h5>
                                    <span>Web design</span>
                                    <ul class="testimonials-ratings">
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    </ul>  
                                </div>
                            </div>
                            <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-15">
                        <div class="testimonials-box-area">
                        <div class="inner p-relative">
                            <div class="upper-area mb-20">
                                <div class="author-image">
                                    <img src={img2} alt="" />
                                </div>
                                <div class="icon-1">
                                    <i class="icon-quote-2"></i>
                                </div>
                                <div class="author-info">
                                    <h5>Darlene Robertson</h5>
                                    <span>Web design</span>
                                    <ul class="testimonials-ratings">
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    </ul>  
                                </div>
                            </div>
                            <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-15">
                        <div class="testimonials-box-area">
                        <div class="inner p-relative">
                            <div class="upper-area mb-20">
                                <div class="author-image">
                                    <img src={img1} alt="" />
                                </div>
                                <div class="icon-1">
                                    <i class="icon-quote-2"></i>
                                </div>
                                <div class="author-info">
                                    <h5>Robin Hood</h5>
                                    <span>Web design</span>
                                    <ul class="testimonials-ratings">
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    </ul>  
                                </div>
                            </div>
                            <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-15">
                        <div class="testimonials-box-area">
                        <div class="inner p-relative">
                            <div class="upper-area mb-20">
                                <div class="author-image">
                                    <img src={img2} alt="" />
                                </div>
                                <div class="icon-1">
                                    <i class="icon-quote-2"></i>
                                </div>
                                <div class="author-info">
                                    <h5>Darlene Robertson</h5>
                                    <span>Web design</span>
                                    <ul class="testimonials-ratings">
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    <li><i class="fas fa-square-star"></i></li>
                                    </ul>  
                                </div>
                            </div>
                            <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <FooterTwo></FooterTwo>
    </>
  );
};

export default Testimonials;