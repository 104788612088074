import React from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img1 from "../../../assets/images/shapes/shape-8.png";
import img2 from "../../../assets/images/resources/testimonial-1.png";
import img3 from "../../../assets/images/resources/testimonial-2.png";

const TestimonialsSection = () => {
 
  const options = {
    autoplay: true,
    smartSpeed: 2000,
    margin: 0,
    loop: true,
    items: 2,
    autoplayHoverPause: true,
    dots: true,
    navText: ['<i className="ti-arrow-left"></i>', '<i className="ti-arrow-right"></i>'],
    nav: true, 
    responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 1,
      },
      700: {
          items: 2,
      },
      1000: {
          items: 2,
      },
  },
  };

  return (

    
    <section className="testimonials-section section-space-bottom p-relative">
      <div className="shape-1" style={{ backgroundImage: `url(${img1})` }}></div>
      <div className="custom-container">
          <div className="testimonials-title-box mb-60 p-relative">
            <div className="row">
                <div className="col-lg-6">
                  <div className="title-box wow fadeInLeft" data-wow-delay=".5s">
                      <span className="section-sub-title">Client Feedback</span>
                      <h3 className="section-title mt-10 mb-25">What Our Customers <br/>Are Talking About</h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse sit amet neque euismod, convallis quam eget, dignissim massa. </p>
                </div>
            </div>
          </div>      
          <ReactOwlCarousel className="testimonial-items testimonial-active-1 owl-carousel" {...options}>
            <div className="item swiper-slide">
              <div className="testimonials-box-area">
                  <div className="inner p-relative">
                    <div className="upper-area mb-20">
                        <div className="author-image">
                          <img src={img2} alt="" />
                        </div>
                        <div className="icon-1">
                          <i className="icon-quote-2"></i>
                        </div>
                        <div className="author-info">
                          <h5>Darlene Robertson</h5>
                          <span>Web design</span>
                            <ul className="testimonials-ratings">
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                          </ul>                          
                        </div>
                    </div>
                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                  </div>
              </div>
            </div>
            <div className="item swiper-slide">
              <div className="testimonials-box-area">
                  <div className="inner p-relative">
                    <div className="upper-area mb-20">
                        <div className="author-image">
                          <img src={img3} alt="" />
                        </div>
                        <div className="icon-1">
                          <i className="icon-quote-2"></i>
                        </div>
                        <div className="author-info">
                          <h5>Robin Hood</h5>
                          <span>Web design</span>
                            <ul className="testimonials-ratings">
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                              <li><i className="fas fa-square-star"></i></li>
                          </ul>
                        </div>
                    </div>
                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante.</p>
                  </div>
              </div>
            </div>
          </ReactOwlCarousel>
      </div>
   </section>

  );
};

export default TestimonialsSection;
